import React, { useEffect, useState } from 'react';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { BASE_URL } from '../../utils/globals';
import { countLines, getPlayer, getTranslation, IconTypes } from '../../utils/utils';
import { orderPedagogicalVideo } from '../../api/pedagogical-videos';
import { orderMovie } from '../../api/movies';
import { getUserIp } from '../../api/ip';
import { usePrevious } from '../ImageEnlarger/UsePrevious';
import { openDeepLink } from '../DeepLinker/DeepLinker';
import codeCountries from '../../assets/data/countries-code.json';
import NotSupported from '../Popup/NotSupported';
import StatusPopup from '../Popup/StatusPopup';
import Modal from '../../templates/Modal/Modal';
import Login from '../Login/Login';

import ArrowDown from '../../assets/images/collection/arrowDown.svg';
import Clock from '../../assets/images/collection/clock.svg';
import DoneIcon from '@mui/icons-material/Done';
import ClipLoader from 'react-spinners/ClipLoader';
import Play from '../../assets/images/collection/play_cined_on.svg';
import PlayDisable from '../../assets/images/collection/play_cined_off.svg';

import './MovieHeader.css';

const Prefix = 'translations.';
const DefaultTranslation = Prefix + 'english';

export default function MovieHeader(props) {
    const {
        isUserLoggedIn,
        isUserTeacher,
        isMovie,
        isOrdered,
        updateIsOrdered,
        setSelectedModal,
        movie,
        studentSheetPDF,
        educationalFile,
        photogramsUrl,
    } = props;
    const [openLogin, setOpenLogin] = useState(false);
    const [isBig, setIsBig] = useState(false);
    const [isGrid, setIsGrid] = useState(false);
    const [openSupported, setOpenSupported] = useState(false);
    const [openStatus, setOpenStatus] = useState(false);
    const [errorDesc, setErrorDesc] = useState('');
    const [success, setSuccess] = useState(true);
    const [loading, setLoading] = useState(true);
    const [orderLoading, setOrderLoading] = useState(false);
    const [countryAvailable, setcountryAvailable] = useState(false);
    const [userCountry, setUserCountry] = useState('');
    const [btnDisabled, setBtnDisabled] = useState(false);
    const prevMovie = usePrevious(movie);

    const { t } = useTranslation();

    useEffect(() => {
        if (!movie || prevMovie?.id === movie?.id) {
            return;
        }
        verifyCountry();

        const buttonNo = getButtonNo(isEducationalFileValid, studentSheetPDF, isStudentSheetValid, isPhotogramValid);
        const linesNo = countLines('headerSynopsis');

        const isContentBig = linesNo > 5 && buttonNo > 1;
        setIsBig(isContentBig);
        setIsGrid(isUserLoggedIn && isUserTeacher && buttonNo > 2);
    }, [movie]);

    //--------------------------------------------------------------------------------------------------
    const getKeyByValue = (obj, val) => {
        return Object.keys(obj).find((key) => obj[key] === val);
    };

    // Get Exhibition Countries
    const getExhibtionCountries = () => {
        const movieExbCountries = movie.ExhibitionCountries;
        let countryCodes;

        if (movieExbCountries === null || movieExbCountries === undefined) {
            countryCodes = [];
        } else {
            const countries = Object.keys(movieExbCountries);
            const exhibitionCountries = countries.filter((e) => movieExbCountries[e] === true);
            countryCodes = exhibitionCountries.map((i) => getKeyByValue(codeCountries, i));
        }

        return countryCodes;
    };

    //Get localization
    const getGeolocalization = async () => {
        setLoading(true);

        let res;
        try {
            res = await getUserIp();
        } catch (error) {
            setLoading(false);
            console.error('An error occurred: ', error);
            return;
        }

        setLoading(false);
        return res.country;
    };

    const verifyCountry = async () => {
        const getExCountries = getExhibtionCountries();
        const getUserCountry = await getGeolocalization();

        const isAvailable = getExCountries.find((e) => e === getUserCountry);

        // Movie is available for streaming in the following three cases:
        // * exhibition countries is not defined for a given film
        // * ipinfo API did not return any result
        // * current country is part of exhibition countries for a given film
        if (getExCountries.length === 0 || !getUserCountry || isAvailable) {
            setcountryAvailable(true);
        } else {
            setcountryAvailable(false);
            if (getUserCountry) {
                setUserCountry(getUserCountry);
            }
        }
    };
    //---------------------------------------------------------------------------------------------------

    const getMovieTranslation = (entry) => {
        return getTranslation(entry, i18next.language, Prefix, DefaultTranslation);
    };

    const getButtonNo = (educationalFile, studentSheetPDF, studentSheet, photogramsUrl) => {
        let buttonNo = 0;

        if (educationalFile) {
            buttonNo++;
        }
        if (studentSheetPDF) {
            buttonNo++;
        }
        if (studentSheet) {
            buttonNo++;
        }
        if (photogramsUrl) {
            buttonNo++;
        }

        return buttonNo;
    };

    const getOrderStatus = (isLogged, isTeacher, isOrdered, isAvailable, vodId, comingSoon, countryAvailable) => {
        const splitWord = comingSoon.split(' ');
        const firstComingSoon = splitWord?.shift();
        const remainComingSoon = splitWord?.join(' ');

        if (loading) {
            return (
                <div className="requestContainer">
                    <ClipLoader
                        color="#FFFFFF"
                        loading={loading}
                        size={125}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                    />
                </div>
            );
        }

        // If user is not logged-in, show "Please sign in to stream film"
        if (!isLogged) {
            return (
                <div className="requestContainer cursor" onClick={() => setOpenLogin(true)}>
                    <div className="playContainer">
                        <img alt="Play Icon Dis" src={PlayDisable} className="playDisableIcon" />
                    </div>

                    <span className="locTxt bigText">{t('Player.PleaseSignIn')}</span>
                </div>
            );
        }

        // If user is logged-in but is not a teacher, show "Please contact your teacher"
        if (!isTeacher) {
            return (
                <div className="requestContainer">
                    <div className="playContainer">
                        <img alt="Play Icon Dis" src={PlayDisable} className="playDisableIcon" />
                    </div>

                    <span className="locTxt bigText">{t('Player.ContactTeacher')}</span>
                </div>
            );
        }

        // If movie is not available in this country, display this information
        if (!countryAvailable) {
            return (
                <div className="requestContainer">
                    <div className="playContainer">
                        <img alt="Play Icon Dis" src={PlayDisable} className="playDisableIcon" />
                    </div>
                    <span className="locTxt bigText">
                        {t('Player.NotAvailable')}
                        {userCountry ? ` (${userCountry})` : ''}
                    </span>
                </div>
            );
        }

        // If movie is not yet available (or not valid), show "Coming Soon"
        if (!isAvailable || !vodId) {
            return (
                <div className="comingSoonContainer">
                    <p className="comingSoon">
                        <span className="highlight">{firstComingSoon}</span>
                        <span className="highlightSpace">&nbsp;</span>
                        {remainComingSoon}
                        <span className="ellipsis">...</span>
                    </p>
                    <img alt="Clock Icon" src={Clock} height="100px" className="clockIcon" />
                </div>
            );
        }

        const PlayIcon = (
            <div className="playContainer cursor" onClick={downloadPlayer}>
                <img alt="Play Icon" src={Play} className="playIcon" />
            </div>
        );

        let item = <></>;
        if (isOrdered) {
            item = (
                <div className="requestContainer">
                    {PlayIcon}

                    <span className="orderTxt h5Text">
                        {t('Player.MovieOrdered')} <DoneIcon className="doneIcon" />
                    </span>
                </div>
            );
        } else {
            item = (
                <div className="requestContainer">
                    {PlayIcon}

                    {!orderLoading ? (
                        <button className="orderButton detailsText" onClick={orderMovieBtn} disabled={btnDisabled}>
                            {t('Player.OrderMovie')}
                        </button>
                    ) : (
                        <></>
                    )}

                    <div className="loadingRequest">
                        <ClipLoader
                            color="#FFFFFF"
                            loading={orderLoading}
                            size={50}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                        />
                    </div>
                </div>
            );
        }

        return item;
    };

    const getMobileOrderBtn = (isLogged, isTeacher, isOrdered, isAvailable, vodId, countryAvailable) => {
        const hasPermissions = isLogged && isTeacher;

        // Return empty div if:
        // - user has no permissions
        // - movie is not available in current country
        // - movie is not available (as set in the back-office)
        // - movie is not available on Vizzi
        if (!hasPermissions || !countryAvailable || !isAvailable || !vodId) {
            return <></>;
        }

        let item = <></>;
        if (isOrdered) {
            item = (
                <span className="txtDetails detailsText mobileOnly">
                    {t('Player.MovieOrdered')} <DoneIcon className="doneIcon" />
                </span>
            );
        } else {
            item = (
                <div>
                    {!orderLoading ? (
                        <button
                            className="buttonDetails detailsText mobileOnly"
                            onClick={orderMovieBtn}
                            disabled={btnDisabled}
                        >
                            {t('Player.OrderMovie')}
                        </button>
                    ) : (
                        <></>
                    )}

                    <div className="loadingRequestMobile mobileOnly">
                        <ClipLoader
                            color="#FFFFFF"
                            loading={orderLoading}
                            size={30}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                        />
                    </div>
                </div>
            );
        }

        return item;
    };

    const orderMovieBtn = async () => {
        setBtnDisabled(true);
        setOrderLoading(true);

        let promises = [];
        if (isMovie) {
            promises.push(orderMovie(movie?.id));
        } else {
            promises.push(orderPedagogicalVideo(movie?.id));
        }

        let res;
        try {
            res = await Promise.all(promises);
        } catch (error) {
            // toast.error(t('Player.OrderNotSuccessful'));
            setOpenStatus(true);
            setSuccess(false);

            setBtnDisabled(false);
            setOrderLoading(false);
            console.error('An error occurred: ', error);
            return;
        }

        const orderRes = res && res.length > 0 ? res[0] : null;
        setBtnDisabled(false);
        setOrderLoading(false);

        if (orderRes?.valid) {
            // toast.success(t('Player.OrderSuccessful'));
            updateIsOrdered(true);
            setOpenStatus(true);
            setSuccess(true);
        } else {
            // toast.error(t('Player.OrderNotSuccessful'));
            setOpenStatus(true);
            setSuccess(false);
            setErrorDesc(orderRes?.message);
        }
    };

    const launchApp = (inProtocol, inInstallLink, inTimeout) => {
        openDeepLink(inProtocol, {
            onFail: function () {
                window.location.href = inInstallLink;
            },
        });
    };

    const downloadPlayer = () => {
        const { deeplink, support, player } = getPlayer();

        if (!support) {
            setOpenSupported(true);
        } else {
            launchApp(deeplink, player, 5000);
        }

        return false;
    };

    const studentSheet = movie?.student_sheet?.id;

    const isEducationalFileValid = isMovie && educationalFile;
    const isStudentSheetValid = studentSheet && isUserLoggedIn;
    const isPhotogramValid = photogramsUrl && isUserLoggedIn && isUserTeacher;

    const wrapperButtonClass = `wrapperButtonsFiles ${isGrid ? 'grid' : 'flex'}`;

    if (!movie) return <></>;

    return (
        <div className={`headerMovieStructure ${isBig ? 'big' : 'small'}`}>
            <img
                className={`headerMovieImg ${isBig ? 'big' : 'small'}`}
                src={`${BASE_URL}${movie.FavoritePhotogram.url}`}
                alt="Header Movie"
            />

            <div className={`headerFilter ${isBig ? 'big' : 'small'}`} />

            <div className="headerContent container">
                <div className="movieContentWrapper">
                    <h1 className="titleInfo h1Text">{movie?.OriginalTitle}</h1>

                    <p className="headerTranslatedTitle h5Text">{getMovieTranslation(movie)?.TitleTranslation}</p>

                    <div className="wrapperDetails">
                        <p className="detailsInfo detailsText">
                            <b>{t('Movie.Director')}</b>: {movie.Director}
                        </p>
                        <p className="detailsInfo detailsText">
                            <b>{t('Movie.Year')}</b>: {movie.Year}
                        </p>
                        <p className="detailsInfo detailsText">
                            <b>{t('Movie.Length')}</b>: {movie.Duration}
                        </p>
                        <p className="detailsInfo detailsText">
                            <b>{t('Movie.Format')}</b>: {movie.Type}
                        </p>
                    </div>

                    {/*movie.RightsExpiryDate ? <p className="rightsInfo detailsText">
                    {t('Movie.RightsExpire')}: {movie.RightsExpiryDate}
                </p> : <></>*/}

                    <div className="wrapperButtonsInfo">
                        <button className="buttonDetails detailsText" onClick={() => setSelectedModal('td')}>
                            {t('Movie.TechnicalData')}
                        </button>

                        {isMovie ? (
                            <button className="buttonDetails detailsText" onClick={() => setSelectedModal('ac')}>
                                {t('Movie.Actors')}
                            </button>
                        ) : (
                            <></>
                        )}
                    </div>

                    <p id="headerSynopsis" className="synopsisInfo detailsText">
                        {getMovieTranslation(movie)?.Synopsis}&nbsp;
                        {!isMovie && educationalFile ? (
                            <>
                                <a
                                    className="educationalFile"
                                    href={`${BASE_URL}${educationalFile}`}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    +{t('NewsEvents.MoreInfo')}
                                </a>
                            </>
                        ) : (
                            <></>
                        )}
                    </p>

                    <div className={wrapperButtonClass}>
                        {isEducationalFileValid ? (
                            <a href={`${BASE_URL}${educationalFile}`} target="_blank" rel="noreferrer">
                                <button className="buttonDetails detailsText">{t('Movie.EducationalFile')}</button>
                            </a>
                        ) : (
                            <></>
                        )}

                        {studentSheetPDF ? (
                            <a href={`${BASE_URL}${studentSheetPDF}`} target="_blank" rel="noreferrer">
                                <button className="buttonDetails detailsText">{t('Movie.SSPDF')}</button>
                            </a>
                        ) : (
                            <></>
                        )}

                        {isStudentSheetValid ? (
                            <div id="ssinteractive">
                                <Link to={`/exA1/${studentSheet}`}>
                                    <button className="buttonDetails detailsText">{t('Movie.SSI')}</button>
                                </Link>
                            </div>
                        ) : (
                            <></>
                        )}

                        {isPhotogramValid ? (
                            <a href={`${BASE_URL}${photogramsUrl}`}>
                                <button className="buttonDetails detailsText">{t('Movie.MovieFrames')}</button>
                            </a>
                        ) : (
                            <></>
                        )}

                        {getMobileOrderBtn(
                            isUserLoggedIn,
                            isUserTeacher,
                            isOrdered,
                            movie?.isAvailable,
                            movie?.VodId,
                            countryAvailable,
                        )}
                    </div>
                </div>

                {getOrderStatus(
                    isUserLoggedIn,
                    isUserTeacher,
                    isOrdered,
                    movie?.isAvailable,
                    movie?.VodId,
                    t('Movie.ComingSoon'),
                    countryAvailable,
                )}
            </div>

            <div className="moreDetailsSection">
                <div className="moreDetailsWrapper">
                    <a href="#movie-details" className="moreDetailsText detailsText">
                        {t('Movie.MoreDetails')}
                    </a>
                    <a href="#movie-details" className="moreDetailsSvg">
                        <img alt="More Details" src={ArrowDown} height="45px" />
                    </a>
                </div>
            </div>

            <NotSupported open={openSupported} closeModal={() => setOpenSupported(false)} />

            <StatusPopup
                open={openStatus}
                iconType={success ? IconTypes.SUCCESS : IconTypes.ERROR}
                title={success ? t('Player.OrderSuccessful') : t('Player.OrderNotSuccessful')}
                description={success ? t('Player.OrderSuccessDesc') : errorDesc}
                closeModal={() => setOpenStatus(false)}
            />

            <Modal
                closeTranslation={'SignInUp.Close'}
                maxWidth="sm"
                fullWidth={true}
                open={openLogin}
                closeModal={() => setOpenLogin(false)}
            >
                <div className="modalBody">
                    <Login />
                </div>
            </Modal>
        </div>
    );
}
