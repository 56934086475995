import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Anim from '../Anim/Anim';
import Popup from './Popup';
import { getPlayer, IconTypes, lang } from '../../utils/utils';
import { ReactComponent as WindowsIcon } from '../../assets/images/windows.svg';
import { ReactComponent as AppleIcon } from '../../assets/images/apple.svg';
import { getOS } from '../../utils/utils';
import MinimumRequirementsModal from './MinimumRequirements';

export default function StatusPopup(props) {
    const { i18n, t } = useTranslation();
    const { support, player } = getPlayer();
    const [isMRmodalOpen, setIsMRmodalOpen] = useState(false);

    const downloadPlayer = () => {
        window.open(player);
    };

    return (
        <Popup maxWidth="md" {...props} isMRmodalOpen={isMRmodalOpen} setIsMRmodalOpen={setIsMRmodalOpen}>
            {isMRmodalOpen ? (
                <MinimumRequirementsModal />
            ) : (
                <div>
                    <Anim iconType={props.iconType} />

                    <div className="centeredContainer column">
                        <h2 lang={lang[i18n.language]} className="modalTitleTD h3Text">
                            {props.title}
                        </h2>

                        {props.iconType === IconTypes.SUCCESS && support ? (
                            <>
                                <p className="normalText marginZero">{props.description}</p>

                                <p className="normalText marginZero">{t('Player.OrderHDPart1')}</p>

                                <p className="normalText marginZero">{t('Player.OrderHDPart2')}</p>

                                <p className="normalText">{t('Player.OrderHDPart3')}</p>
                            </>
                        ) : (
                            <></>
                        )}

                        {props.iconType === IconTypes.SUCCESS && support ? (
                            <div className="normalText downloadAnchor playerLinkContainer">
                                <div className="playerLink" onClick={downloadPlayer}>
                                    <span>{t('NavBar.VideoPlayer')}</span>{' '}
                                    {getOS() === 'Mac OS' ? <AppleIcon /> : <WindowsIcon />}
                                </div>
                                <div className="playerLinkFooter">
                                    <p>
                                        {t('Player.AlsoAvailable')}{' '}
                                        {getOS() === 'Mac OS' ? <WindowsIcon /> : <AppleIcon />}
                                    </p>
                                </div>
                                <div className="PopupContainer">
                                    <a onClick={() => setIsMRmodalOpen(true)}>
                                        {t('Player.MinimumRequirementsButton')}
                                    </a>
                                </div>
                            </div>
                        ) : (
                            <></>
                        )}
                    </div>
                </div>
            )}
        </Popup>
    );
}
