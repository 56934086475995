import React from 'react';
import i18next from 'i18next';
import { Redirect } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import Body from '../../templates/Body/Body';
import BodyExercise from '../BodyExercise/BodyExercise';
import ExerciseIcons from '../BodyExercise/ExerciseIcons';
import Loading from '../../components/Loading/Loading';
import { BASE_URL } from '../../utils/globals';
import { getStudentSheetById } from '../../api/student-sheets';
import { DescriptionTranslations, getExerciseDescription, getExerciseText } from '../../utils/ssUtils';

import arrowRight from '../../assets/images/student-sheets/nextContent.svg';
import arrowLeft from '../../assets/images/student-sheets/prevContent.svg';
import icon from '../../assets/images/student-sheets/readActivity.svg';
import './ExB2.css';

class ExB2Class extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            ex: null,
            loading: true,
            error: false,
            posterIndex: 0,
            oneImage: true,
            firstPoster: true,
            lastPoster: false,
            viewMode: false,
        };
        this.nextPoster = this.nextPoster.bind(this);
        this.prevPoster = this.prevPoster.bind(this);
    }

    componentDidMount = async () => {
        const ssId = this.props.match.params.id;
        localStorage.setItem('lastPage', '/exB2');

        let response;
        try {
            response = await getStudentSheetById(ssId);
        } catch (error) {
            console.error('An error occurred: ', error);
            this.setState({ error: true });
            return;
        }

        this.setState({
            ex: response,
            loading: false,
        });

        const exerciseDetails = response?.B2?.Posters;
        if (exerciseDetails?.length <= 1) {
            this.setState({ lastPoster: true });
        }

        if (localStorage.getItem('disable')) {
            this.setState({ viewMode: true });
        }
    };

    prevPoster() {
        const index = this.state.posterIndex - 1;
        if (index === 0) {
            this.setState({ firstPoster: true });
        }

        this.setState({
            posterIndex: index,
            lastPoster: false,
        });
    }

    nextPoster() {
        const exerciseDetails = this.state.ex.B2.Posters;
        const index = this.state.posterIndex + 1;

        if (index === exerciseDetails.length - 1) {
            this.setState({ lastPoster: true });
        }

        this.setState({
            posterIndex: index,
            firstPoster: false,
        });
    }

    render() {
        const checkToken = localStorage.getItem('token');
        if (!checkToken) {
            return <Redirect to="/no-account" />;
        }

        if (this.state.error) {
            return <Redirect to="/page-not-found" />;
        }

        if (this.state.loading) {
            return (
                <Body bodyClass="content" title="B2 Exercise | CinEd">
                    <Loading />
                </Body>
            );
        }

        const exercise = this.state.ex;
        const exerciseDetails = exercise?.B2?.Posters;

        const description = getExerciseDescription(this.state.ex, 'B2', i18next.language, DescriptionTranslations);

        return (
            <BodyExercise
                selectedOption="exB2"
                studentSheetId={this.props.match.params.id}
                movieTitle={exercise?.MovieTitle}
                pageTitle={`B2 Exercise | ${exercise?.MovieTitle} | CinEd`}
                viewMode={this.state.viewMode}
                interactiveEx={false}
                prevLink={`/exB1/${this.props.match.params.id}`}
                nextLink={`/exC11/${this.props.match.params.id}`}
            >
                <ExerciseIcons
                    studentSheetId={this.props.match.params.id}
                    exIcon={icon}
                    exTitle="StudentSheetsLeftMenu.FilmAndItsEra"
                    exDescription={description}
                    viewMode={this.state.viewMode}
                />

                <div className="exerciseB2Container">
                    <div>
                        <div className="exB2ImgContainer">
                            <img
                                className="imageExerciseB2"
                                src={`${BASE_URL}${exerciseDetails[this.state.posterIndex]?.url}`}
                                alt="Posters"
                            />
                        </div>

                        <div className="imageArrowsStructure">
                            <div className={this.state.firstPoster ? 'inactive-arrows' : 'active-arrows'}>
                                <img className="prevPoster" alt="pPoster" src={arrowLeft} onClick={this.prevPoster} />
                            </div>
                            <div className={this.state.lastPoster ? 'inactive-arrows' : 'active-arrows'}>
                                <img className="nextPoster" alt="nPoster" src={arrowRight} onClick={this.nextPoster} />
                            </div>
                        </div>
                    </div>
                    <div className="scrollBar">
                        <p className="textExerciseB2 ssNormalText">
                            {getExerciseText(this.state.ex, 'B2', i18next.language)}
                        </p>
                    </div>
                </div>
            </BodyExercise>
        );
    }
}

export default withTranslation(['translation'])(ExB2Class);
