import React from 'react';
import { withTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import NotSupported from '../../components/Popup/NotSupported';
import { getPlayer } from '../../utils/utils';
import DropdownPopup from '../../components/Popup/DropdownPopup';
import ArrowDown from '@mui/icons-material/KeyboardArrowDownRounded';
import DownloadIcon from '@mui/icons-material/GetAppRounded';

class UserDropdown extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            open: false,
            openPlayerPopup: false,
        };
    }

    getUsername = (user) => {
        const name = user?.name;
        const firstName = name?.substring(0, name.indexOf(' '));
        return firstName ? firstName : name;
    };

    matchAuthPages(match, location) {
        if (location.pathname === '/login') {
            return true;
        } else if (location.pathname === '/registration') {
            return true;
        }

        return false;
    }

    downloadPlayer() {
        const { support, player } = getPlayer();

        if (!support) {
            this.setState({ open: true });
        } else {
            window.open(player);
        }
    }

    openModal() {
        this.setState({ openPlayerPopup: true });
    }

    render() {
        const { t, user } = this.props;
        const { open, openPlayerPopup } = this.state;
        const { support } = getPlayer();

        return (
            <div className="dropdownContainer">
                <DropdownPopup open={openPlayerPopup} closeModal={() => this.setState({ openPlayerPopup: false })} />
                {this.props.isUserLoggedIn ? (
                    <div className="userDropdown">
                        <NavLink id="user" className="userLink" to="#">
                            <label>{this.getUsername(user)}</label>
                            <ArrowDown />
                        </NavLink>

                        <div className="submenuDropdown">
                            <a href="/user/profile">{t('NavBar.MyProfile')}</a>

                            {user?.customerId && user?.userRole.toLowerCase() !== 'student' ? (
                                <a href="/user/orders">{t('NavBar.Orders')}</a>
                            ) : (
                                <></>
                            )}

                            {support && user?.customerId && user?.userRole.toLowerCase() !== 'student' ? (
                                <span
                                    className="downloadAnchor spanLink"
                                    onClick={() => this.setState({ openPlayerPopup: true })}
                                >
                                    <span>{t('NavBar.VideoPlayer')}</span> <DownloadIcon />
                                </span>
                            ) : (
                                <></>
                            )}

                            <span className="spanLink" onClick={this.props.logout}>
                                {t('NavBar.SignOut')}
                            </span>
                        </div>
                    </div>
                ) : (
                    <NavLink
                        className="rightLink"
                        activeClassName="activeLink"
                        isActive={this.matchAuthPages.bind(this)}
                        to="/login"
                    >
                        {t('NavBar.SignIn')}
                    </NavLink>
                )}

                <NotSupported open={open} closeModal={() => this.setState({ open: false })} />
            </div>
        );
    }
}

export default withTranslation(['translation'])(UserDropdown);
