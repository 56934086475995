import React from 'react';
import { withTranslation } from 'react-i18next';

import Loading from '../../components/Loading/Loading';
import VideoCard from '../../components/VideoCard/VideoCard';
import { getMovies } from '../../api/movies';

import './Movie.css';

class RelatedMovies extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            movies: [],
            loading: true,
        };
        this.getRelatedMovies = this.getRelatedMovies.bind(this);
    }

    async componentDidMount() {
        this.getRelatedMovies();
    }

    async componentDidUpdate(prevProps, prevState) {
        if (this.props.currentMovieId !== prevProps.currentMovieId) {
            this.getRelatedMovies();
        }
    }

    async getRelatedMovies() {
        const currentMovieId = this.props.currentMovieId;

        let moviesList;
        try {
            moviesList = await getMovies();
        } catch (error) {
            console.error('An error occurred: ', error);
            return;
        }

        const films = moviesList.sort(function (a, b) {
            // Turn your strings into dates, and then subtract them
            // to get a value that is either negative, positive, or zero.
            return new Date(b.createdAt) - new Date(a.createdAt);
        });

        const newArray = films.filter((obj) => obj.id !== currentMovieId);

        this.setState({
            movies: newArray,
            loading: false,
        });
    }

    shuffle(array) {
        let currentIndex = array.length;
        let randomIndex = -1;

        // While there remain elements to shuffle...
        while (currentIndex !== 0) {
            // Pick a remaining element...
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex--;

            // And swap it with the current element.
            [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
        }

        return array;
    }

    render() {
        const { t } = this.props;
        const m = this.state.movies;
        const threeMovies = m.slice(0, 3);

        if (this.state.loading) {
            return <Loading />;
        }

        return (
            <div className="otherMovies">
                <p className="sectionTitle">{t('Movie.OtherMovies')}</p>

                <div className="setMovies">
                    <div className="figureStructureRelatedMovie">
                        {threeMovies.map((movie) => (
                            <VideoCard key={movie.id} movie={movie} isMovie={true} />
                        ))}
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation(['translation'])(RelatedMovies);
