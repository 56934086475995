import React from 'react';

export default function AtlasIcon({ fill }) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="97" height="97" viewBox="0 0 97 97" fill="none">
            <g clipPath="url(#clip0_471_553)">
                <path
                    d="M18.9301 32.3299H18.8101V29.6799C18.8101 30.5799 18.8501 31.4599 18.9301 32.3299Z"
                    fill={fill}
                />
                <path
                    d="M48.5001 0C32.2101 0 18.8101 13.4 18.8101 29.69C18.8101 30.58 18.8501 31.46 18.9301 32.34C19.2401 35.77 20.1401 39.04 21.5301 42.04L31.5301 64.67L44.7301 94.55C46.1801 97.83 50.8301 97.83 52.2701 94.55L65.4701 64.67L75.4701 42.04C76.8601 39.03 77.7601 35.76 78.0701 32.34C78.1501 31.47 78.1901 30.58 78.1901 29.69C78.1901 13.4 64.7901 0 48.5001 0ZM48.5001 46.39C40.2401 46.39 33.3001 40.26 32.0101 32.34C31.8701 31.48 31.8001 30.59 31.8001 29.69C31.8001 20.53 39.3401 12.99 48.5001 12.99C57.6601 12.99 65.2001 20.53 65.2001 29.69C65.2001 30.59 65.1301 31.47 64.9901 32.34C63.7001 40.26 56.7601 46.39 48.5001 46.39Z"
                    fill={fill}
                />
            </g>
            <defs>
                <clipPath id="clip0_471_553">
                    <rect width="97" height="97" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}
