import React from 'react';
import { NavLink } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import './ArrowsMain.css';

class ArrowsMain extends React.Component {
    render() {
        const { interactiveEx, prevLink, nextLink, lastEx, viewMode, t } = this.props;

        return (
            <div className="containerArrows">
                <NavLink
                    className="previousExerciseButton"
                    to={prevLink ? prevLink : '#'}
                    style={{ visibility: prevLink ? 'visible' : 'hidden' }}
                >
                    {t(nextLink ? 'StudentSheetsButtons.PreviousExercise' : 'StudentSheetsButtons.Back')}
                </NavLink>

                {(lastEx && viewMode) || !nextLink ? (
                    <></>
                ) : (
                    <div>
                        <NavLink className="nextExerciseButton" to={nextLink}>
                            {t(lastEx ? 'StudentSheetsButtons.Finnish' : 'StudentSheetsButtons.NextExercise')}
                        </NavLink>

                        {viewMode || !interactiveEx ? (
                            <></>
                        ) : (
                            <p className="nextExerciseDescription">{t('StudentSheetsButtons.SaveBeforeNext')}</p>
                        )}
                    </div>
                )}
            </div>
        );
    }
}
export default withTranslation(['translation'])(ArrowsMain);
