import React from 'react';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import { withTranslation } from 'react-i18next';

import Modal from '../../templates/Modal/Modal';
import { createUser } from '../../api/users';
import { validateForm } from '../../utils/regex';

import VisibilityIcon from '@mui/icons-material/Visibility';

const eye = <VisibilityIcon fontSize="medium" />;

const FormDefault = {
    name: '',
    username: '',
    email: '',
    country: '',
    city: '',
    school: '',
    password: '',
    question: '',
    answer: '',
    errorMessage: '',
    successMessage: '',
    passwordShown: false,
};

class SignUpClass extends React.Component {
    constructor() {
        super();
        this.state = FormDefault;
    }

    onChange = (e) => {
        if (e.target.name === 'username' || e.target.name === 'email') {
            this.setState({ [e.target.name]: e.target.value.trim() });
        } else {
            this.setState({ [e.target.name]: e.target.value });
        }
    };

    selectOption(option, val) {
        this.setState({ [option]: val });
    }

    togglePasswordVisiblity = () => {
        this.setState({ passwordShown: !this.state.passwordShown });
    };

    handleRegister = async (e) => {
        e.preventDefault();

        this.setState({
            successMessage: this.props.t('SignInUp.Loading') + ' ...',
            errorMessage: '',
        });

        const { error, message } = validateForm(this.state.username, this.state.password);

        if (error) {
            this.setState({
                successMessage: '',
                errorMessage: this.props.t(message),
            });
            return;
        }

        const data = {
            username: this.state.username,
            email: this.state.email,
            password: this.state.password,
            confirmed: true,
            name: this.state.name,
            country: this.state.country,
            city: this.state.city,
            school: this.state.school,
            userRole: 'Student',
            securityQuestion: this.state.question,
            securityAnswer: this.state.answer,
        };

        try {
            await createUser(data);
        } catch (error) {
            const errorData = error?.data;
            const errorMessage = errorData && errorData.length > 0 ? errorData[0]?.messages : null;
            const message = errorMessage && errorMessage.length > 0 ? errorMessage[0] : null;
            let errorMsg = this.props.t('MyOrders.ErrorOcurred');

            switch (message?.id) {
                case 'Auth.form.error.email.taken':
                    errorMsg = this.props.t('SignInUp.EmailTaken');
                    break;
                case 'Auth.form.error.username.taken':
                    errorMsg = this.props.t('SignInUp.UsernameTaken');
                    break;
                default:
                    errorMsg = message ? message.message : this.props.t('MyOrders.ErrorOcurred');
            }

            // Handle error.
            this.setState({
                errorMessage: errorMsg,
                successMessage: '',
            });
            return;
        }

        // Handle success.
        this.setState({
            successMessage: this.props.t('SignInUp.AccountRegistered') + '!',
            errorMessage: '',
        });
    };

    handleCloseModal(closeModal) {
        this.setState(FormDefault);
        closeModal();
    }

    render() {
        const { t, open, closeModal } = this.props;
        const { name, email, username, password, country, city, school, question, answer, passwordShown } = this.state;

        return (
            <Modal
                id="cs"
                titleClass="signInModalTitle h4Text"
                title={t('SignInUp.StudentRegistration')}
                closeTranslation={'SignInUp.Close'}
                open={open}
                closeModal={() => this.handleCloseModal(closeModal)}
            >
                <div className="modalBody">
                    <form onSubmit={this.handleRegister}>
                        <div className="registerInputsWrapper">
                            <div>
                                <input
                                    className="inputRegister"
                                    type="text"
                                    name="name"
                                    placeholder={t('SignInUp.RegisterName')}
                                    value={name}
                                    onChange={this.onChange}
                                    required={true}
                                />
                                <div className="fieldsDivision" />

                                <input
                                    className="inputRegister"
                                    type="text"
                                    name="username"
                                    placeholder={t('SignInUp.RegisterUsername')}
                                    value={username}
                                    onChange={this.onChange}
                                    required={true}
                                />
                                <div className="fieldsDivision" />

                                <input
                                    className="inputRegister"
                                    type="email"
                                    name="email"
                                    placeholder={t('SignInUp.RegisterEmail')}
                                    value={email}
                                    onChange={this.onChange}
                                    required={true}
                                />
                                <div className="fieldsDivision" />

                                <CountryDropdown
                                    className="dropdownRegister"
                                    value={country}
                                    required={true}
                                    onChange={(val) => this.selectOption('country', val)}
                                    defaultOptionLabel={t('SignInUp.SelectCountry')}
                                />
                                <div className="fieldsDivision" />

                                <RegionDropdown
                                    className="dropdownRegister"
                                    country={country}
                                    required={true}
                                    value={city}
                                    onChange={(val) => this.selectOption('city', val)}
                                    defaultOptionLabel={t('SignInUp.SelectRegion')}
                                />
                                <div className="fieldsDivision" />
                            </div>

                            <div>
                                <input
                                    className="inputRegister"
                                    type="text"
                                    name="school"
                                    placeholder={t('SignInUp.RegisterSchool')}
                                    value={school}
                                    onChange={this.onChange}
                                    required={true}
                                />
                                <div className="fieldsDivision" />

                                <input
                                    className="inputRegister"
                                    type="text"
                                    name="question"
                                    placeholder={t('SignInUp.SecurityQuestion')}
                                    value={question}
                                    onChange={this.onChange}
                                    required={true}
                                />
                                <div className="fieldsDivision" />

                                <input
                                    className="inputRegister"
                                    type="text"
                                    name="answer"
                                    placeholder={t('SignInUp.SecurityAnswer')}
                                    value={answer}
                                    onChange={this.onChange}
                                    required={true}
                                />
                                <div className="fieldsDivision" />

                                <div className="passwordRegisterWrappper">
                                    <input
                                        className="inputRegister"
                                        type={passwordShown ? 'text' : 'password'}
                                        name="password"
                                        value={password}
                                        placeholder={t('SignInUp.RegisterPassword')}
                                        onChange={this.onChange}
                                        required={true}
                                    />
                                    <i className="eyeSignUp" onClick={this.togglePasswordVisiblity}>
                                        {eye}
                                    </i>
                                    <div className="fieldsDivision" />
                                </div>
                            </div>
                        </div>

                        {this.state.errorMessage ? <p className="error">{this.state.errorMessage}</p> : <></>}

                        {this.state.successMessage ? <p className="success">{this.state.successMessage}</p> : <></>}

                        <button type="submit" className="submitRegistration detailsText">
                            {t('SignInUp.Register')}
                        </button>
                    </form>
                </div>
            </Modal>
        );
    }
}

export default withTranslation(['translation'])(SignUpClass);
