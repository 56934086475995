import React, { Component } from 'react';
import { Helmet } from 'react-helmet-async';

export default class MetaTags extends Component {
    render() {
        let title = 'CinEd';
        let description =
            `CinEd is a European cooperation project co-funded by the Creative Europe / MEDIA programme of ` +
            `the European Union, "Support for film education".`;

        if (this.props.title) {
            title = this.props.title;
        }
        if (this.props.description) {
            description = this.props.description;
        }

        return (
            <Helmet>
                <meta charSet="utf-8" />
                <title>{title}</title>
                <meta name="description" content={description} />
                <meta name="robots" content="index, follow" />
            </Helmet>
        );
    }
}
