import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import AllPages from './components/AllPages/AllPages';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import AboutTheProject from './pages/AboutTheProject/AboutTheProject';
import Homepage from './pages/Homepage/Homepage';
import NoAccount from './pages/NoAccount/NoAccount';
import Page404 from './pages/Page404/Page404';
import TeachingResources from './pages/TeachingResources/TeachingResources';
import Contacts from './pages/Contacts/Contacts';
import SignIn from './pages/SignInUp/SignIn';
import SignUp from './pages/SignInUp/SignUp';
import Collection from './pages/Collection/Collection';
import Movie from './pages/Movie/Movie';
import NewsEvents from './pages/NewsEvents/NewsEvents';
import NewsEventsDetails from './pages/NewsEvents/NewEventDetails';
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy';
import ExA1 from './studentSheets/ExA1/ExA1';
import ExA2 from './studentSheets/ExA2/ExA2';
import ExA3 from './studentSheets/ExA3/ExA3';
import ExB1 from './studentSheets/ExB1/ExB1';
import ExB2 from './studentSheets/ExB2/ExB2';
import ExC11 from './studentSheets/ExC1/ExC11/ExC11';
import ExC12 from './studentSheets/ExC1/ExC12/ExC12';
import ExC21 from './studentSheets/ExC2/ExC21/ExC21';
import ExC22 from './studentSheets/ExC2/ExC22/ExC22';
import ExC23 from './studentSheets/ExC2/ExC23/ExC23';
import ExD from './studentSheets/ExD/ExD';
import Share from './studentSheets/ShareStudentSheet/share';
import PedagogicalVideo from './pages/PedagogicalVideo/PedagogicalVideo';
import Migration from './pages/Migration/Migration';
import FAQ from './pages/FAQ/FAQ';
import UserLayout from './templates/UserLayout/UserLayout';
import { refreshSession } from './api/users';

class Routes extends Component {
    constructor(props) {
        super(props);

        this.state = {
            pathname: window.location.pathname,
        };
    }

    async componentDidMount() {
        try {
            const user = await refreshSession();
            localStorage.setItem('user', JSON.stringify(user, null, 2));
        } catch (err) {
            console.log(' [auth] User is not authenticated.');

            const isLoggedIn = localStorage.getItem('token');
            if (isLoggedIn) {
                localStorage.removeItem('token');
                localStorage.removeItem('user');
                window.location.reload();
            }

            return;
        }
    }

    render() {
        return (
            <Router>
                <ScrollToTop />
                <Route path="/" render={(props) => <AllPages {...props} />} />
                <Switch>
                    <Route exact path="/" component={Homepage} />
                    <Route path="/collection" component={Collection} />
                    <Route path="/collection#thematic-videos" component={Collection} />
                    <Route path="/movies/:id" component={Movie} />
                    <Route path="/pedagogical-videos/:id" component={PedagogicalVideo} />
                    <Route path="/news-events" component={NewsEvents} />
                    <Route path="/details/:id" component={NewsEventsDetails} />
                    <Route path="/teaching-with-cined" component={TeachingResources} />
                    <Route path="/about-project" component={AboutTheProject} />
                    <Route path="/contacts" component={Contacts} />
                    <Route path="/login" component={SignIn} />
                    <Route path="/registration" component={SignUp} />
                    <Route path="/frequently-asked-questions" component={FAQ} />
                    <Route path="/migration/:id/:type" component={Migration} />
                    <Route path="/privacy-policy" component={PrivacyPolicy} />
                    <Route path="/page-not-found" component={Page404} />

                    <Route path="/exA1/:id" component={ExA1} />
                    <Route path="/exA2/:id" component={ExA2} />
                    <Route path="/exA3/:id" component={ExA3} />
                    <Route path="/exB1/:id" component={ExB1} />
                    <Route path="/exB2/:id" component={ExB2} />
                    <Route path="/exC11/:id" component={ExC11} />
                    <Route path="/exC12/:id" component={ExC12} />
                    <Route path="/exC21/:id" component={ExC21} />
                    <Route path="/exC22/:id" component={ExC22} />
                    <Route path="/exC23/:id" component={ExC23} />
                    <Route path="/exD/:id" component={ExD} />
                    <Route path="/share/:id" component={Share} />
                    <Route path="/no-account" component={NoAccount} />

                    <Route path="/user">
                        <UserLayout />
                    </Route>

                    <Redirect to="/" />
                </Switch>
            </Router>
        );
    }
}

export default withTranslation(['translation'])(Routes);
