import React from 'react';
import { withTranslation } from 'react-i18next';

import ModalPhotoGallery from './ModalPhotoGallery';
import ModalVideoGallery from './ModalVideoGallery';
import { BASE_URL } from '../../utils/globals';

import PlayArrowIcon from '@mui/icons-material/PlayArrowRounded';
import './MoviePhotoVideos.css';

class MoviePhotoVideos extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openPhotosModal: false,
            openModal1: false,
            imgIdx: 0,
            videoIdx: 0,
        };

        this.hldOpenPhotos = this.hldOpenPhotos.bind(this);
        this.hldClosePhotos = this.hldClosePhotos.bind(this);

        this.hldOpenVideos = this.hldOpenVideos.bind(this);
        this.hldCloseVideos = this.hldCloseVideos.bind(this);
    }

    hldOpenPhotos = (e) => {
        this.setState({ openPhotosModal: true, imgIdx: e.target.id });
    };

    hldClosePhotos = () => {
        this.setState({ openPhotosModal: false });
    };

    hldOpenVideos = (e) => {
        this.setState({ openModal1: true, videoIdx: e });
    };

    hldCloseVideos = () => {
        this.setState({ openModal1: false });
    };

    render() {
        const { t, photoGallery, videoGallery } = this.props;

        return (
            <div className="photoVideoWrapper" id="movie-details">
                <div className="galleryPhotosStructure">
                    <p className="galleryPhotosTitle h4Text">{t('Movie.PhotosGallery')}</p>

                    {photoGallery ? (
                        <div className="gridPhoto">
                            {photoGallery.map((photo) => (
                                <img
                                    key={photo.id}
                                    id={photoGallery.findIndex((el) => el.id === photo.id)}
                                    src={`${BASE_URL}${photo.url}`}
                                    className="imagesGallery"
                                    onClick={(e) => this.hldOpenPhotos(e)}
                                    alt="Movie Screencap"
                                />
                            ))}
                        </div>
                    ) : (
                        t('Movie.NoPhotos')
                    )}
                </div>
                <div className="galleryPhotosStructure">
                    <p className="galleryPhotosTitle h4Text">{t('Movie.VideoGallery')}</p>

                    {videoGallery ? (
                        <div className="gridPhoto">
                            {videoGallery.map((video, index) => (
                                <div
                                    key={index}
                                    className="video-player-container"
                                    onClick={() =>
                                        this.hldOpenVideos(videoGallery.findIndex((el) => el.id === video.id))
                                    }
                                >
                                    <video
                                        //controls
                                        className="videoPlay"
                                    >
                                        <source src={`${BASE_URL}${video.url}`} type="video/mp4" />
                                    </video>

                                    <div className="play-icon">
                                        <PlayArrowIcon sx={{ color: 'white', width: '50px', height: '50px' }} />
                                    </div>
                                </div>
                            ))}
                        </div>
                    ) : (
                        t('Movie.NoVideos')
                    )}
                </div>
                {this.state.openPhotosModal === true ? (
                    <ModalPhotoGallery
                        openModal={this.state.openPhotosModal}
                        hdlClose={this.hldClosePhotos}
                        gallery={photoGallery}
                        imgIdx={this.state.imgIdx}
                    />
                ) : (
                    <></>
                )}
                {this.state.openModal1 === true ? (
                    <ModalVideoGallery
                        openModal={this.state.openModal1}
                        hdlClose={this.hldCloseVideos}
                        gallery={videoGallery}
                        imgIdx={this.state.videoIdx}
                    />
                ) : (
                    <></>
                )}
            </div>
        );
    }
}

export default withTranslation(['translation'])(MoviePhotoVideos);
