import React from 'react';

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

import Gallery from '../Gallery/Gallery';

import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import './ModalPhotoVideoGallery.css';

class ModalPhotoGallery extends React.Component {
    render() {
        return (
            <Modal
                open={this.props.openModal}
                onClose={this.props.hdlClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{
                    '*': {
                        boxSizing: 'content-box !important',
                    },
                    '::after, ::before': {
                        boxSizing: 'content-box !important',
                    },
                }}
            >
                <Box
                    className="box"
                    sx={{
                        bgcolor: 'background.paper',
                        p: 4,
                    }}
                >
                    <div className="moviePhotoGallery">
                        <div style={{ textAlign: 'right' }}>
                            <IconButton sx={{ zIndex: '1', marginLeft: 'auto' }} onClick={this.props.hdlClose}>
                                <CloseIcon />
                            </IconButton>
                        </div>

                        <Gallery img={this.props.gallery} imgIdx={this.props.imgIdx} />
                    </div>
                </Box>
            </Modal>
        );
    }
}

export default ModalPhotoGallery;
