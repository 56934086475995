import React from 'react';
import { withTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { Tooltip } from '@mui/material';

import LanguageSelector from '../../components/LanguageSelector/LanguageSelector';
import UserDropdown from '../UserDropdown/UserDropdown';
import { getStudentSheetAnswer } from '../../api/student-sheets';
import { removeSSFromStorage } from '../../utils/ssUtils';

import go from '../../assets/images/codeBox.svg';
import logo from '../../assets/images/logo.svg';
import world from '../../assets/images/worldwide.png';
import './NavBar.css';

class DesktopNavBar extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            code: '',
            errorMessage: '',
            successMessage: '',
        };
    }

    onChange = (e) => {
        this.setState({ code: e.target.value });
    };

    //get da student sheet a ser paritlhada + campos da student sheet original
    handleSubmit = async (e) => {
        e.preventDefault();

        if (!this.state.code) {
            this.setState({ errorMessage: this.props.t('NavBar.InvalidCode') });
            return;
        }

        this.setState({
            successMessage: this.props.t('SignInUp.Loading') + ' ...',
            errorMessage: '',
        });

        let res;

        try {
            res = await getStudentSheetAnswer(`?Code=${this.state.code}`);
        } catch (error) {
            console.log('An error occurred: ', error);
        }

        const ss = res.length > 0 ? res[0] : null;

        if (!ss) {
            this.setState({
                successMessage: '',
                errorMessage: this.props.t('NavBar.InvalidCode'),
            });
        } else {
            this.setState({
                successMessage: this.props.t('SignInUp.Loading') + ' ...',
            });

            localStorage.setItem('watchStudentSheetId', ss.id);
            localStorage.setItem('disable', true);

            window.location = `/exA1/${ss.student_sheet?.id}`;
        }
    };

    goMovies() {
        removeSSFromStorage();

        localStorage.setItem('label', 'Movies');
        window.location = '/collection#movie-collection';
    }

    goThematicVideos() {
        removeSSFromStorage();

        localStorage.setItem('label', 'ThematicVideos');
        window.location = '/collection#thematic-videos';
    }

    matchCollectionPages(match, location) {
        if (location.pathname.startsWith('/collection')) {
            return true;
        } else if (location.pathname.startsWith('/movies')) {
            return true;
        }

        return false;
    }

    render() {
        const { t, user } = this.props;
        const { code } = this.state;

        return (
            <div>
                <nav className="navbar">
                    <div className="navcontainer-fluid">
                        <div className="nav navbar-nav">
                            <div className="leftLinksContainer">
                                <a href="/">
                                    <img src={logo} className="logoNav" alt="CinEd Logo" />
                                </a>

                                <div className="dropdownMovies">
                                    <NavLink
                                        id="movies"
                                        className="leftLink"
                                        activeClassName="activeLink"
                                        isActive={this.matchCollectionPages.bind(this)}
                                        to="/collection"
                                    >
                                        {t('NavBar.Collection')}
                                    </NavLink>

                                    <div className="submenuDropdown">
                                        <span className="dropdwnopt" onClick={this.goMovies}>
                                            {t('NavBar.Movies')}
                                        </span>
                                        <span className="dropdwnopt" onClick={this.goThematicVideos}>
                                            {t('Collection.PedagogicalVideo')}
                                        </span>
                                    </div>
                                </div>

                                <NavLink className="leftLink" activeClassName="activeLink" to="/teaching-with-cined">
                                    {t('NavBar.TeachingResources')}
                                </NavLink>

                                <NavLink className="leftLink" activeClassName="activeLink" to="/about-project">
                                    {t('NavBar.Project')}
                                </NavLink>

                                <NavLink className="leftLink" activeClassName="activeLink" to="/news-events">
                                    {t('NavBar.EventsNews')}
                                </NavLink>

                                <Tooltip title={<h6>{t('NavBar.CinedAtlas')}</h6>} arrow placement="right">
                                    <a
                                        href="https://atlas.cined.eu/"
                                        className="leftLink navbarImgOption"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <img src={world} alt="CinEd Atlas" />
                                    </a>
                                </Tooltip>
                            </div>
                        </div>

                        <div className="navbarRight">
                            <form onSubmit={this.handleSubmit}>
                                <div className="codeBoxWrapper">
                                    <div className="shareCodeFormStudents">
                                        <input
                                            className="placeholder"
                                            type="code"
                                            name="code"
                                            placeholder={t('NavBar.Code')}
                                            value={code}
                                            onChange={this.onChange}
                                            required={true}
                                        />
                                    </div>

                                    <div>
                                        <img src={go} className="codeBoxGo" alt="OK Icon" onClick={this.handleSubmit} />
                                    </div>
                                </div>

                                {this.state.errorMessage ? (
                                    <p className="invalidCode">
                                        <b>{this.state.errorMessage}</b>
                                    </p>
                                ) : (
                                    <></>
                                )}

                                {this.state.successMessage ? (
                                    <p className="validCode">
                                        <b>{this.state.successMessage}</b>
                                    </p>
                                ) : (
                                    <></>
                                )}
                            </form>

                            <div className="rightLinksContainer">
                                <NavLink className="rightLink" activeClassName="activeLink" to="/contacts">
                                    {t('NavBar.Contacts')}
                                </NavLink>

                                <UserDropdown
                                    isUserLoggedIn={this.props.isUserLoggedIn}
                                    user={user}
                                    logout={this.props.logout}
                                />

                                <LanguageSelector navbar={true} />
                            </div>
                        </div>
                    </div>
                </nav>

                <div className="navbar-placeholder" />
            </div>
        );
    }
}

export default withTranslation(['translation'])(DesktopNavBar);
