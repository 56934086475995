import React from 'react';
import { withTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import Partners from '../../assets/images/footer/footer_partners.png';
import Facebook from '../../assets/images/footer/facebook.svg';
import logo from '../../assets/images/logo.svg';
import europeFlag from '../../assets/images/footer/creative-europe.png';
import './Footer.css';

class FooterClass extends React.Component {
    render() {
        const { t } = this.props;

        return (
            <div>
                <div className="mainFooter">
                    <div className="footerFirstColumn">
                        <div className="footerLogoContainer">
                            <img src={logo} alt="CinEd Logo" height="50px" />

                            <a href="https://www.facebook.com/cinedEU" target="_blank" rel="noreferrer">
                                <p className="facebookSentence">{t('Footer.Facebook')}!</p>

                                <img src={Facebook} alt="Facebook Logo" height="30px" />
                            </a>
                        </div>

                        <div className="footerAboutContainer">
                            <p className="footerAbout">{t('Footer.About')}</p>
                        </div>
                    </div>

                    <div className="footerSecondColumn">
                        <img src={Partners} alt="Partners" height="150px" />
                    </div>
                </div>

                <div className="secondaryFooterContainer">
                    <div className="secondaryFooter">
                        <img className="footerEurope" src={europeFlag} alt="Europe Flag" />

                        <div className="footerText">
                            <p>{t('Footer.Footer')}</p>

                            <NavLink className="footerLink" to="/privacy-policy">
                                {t('PrivacyPolicy.Title')}
                            </NavLink>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation(['translation'])(FooterClass);
