import React from 'react';

import NavBarComponent from '../../templates/NavBar/NavBar';
import MetaTags from '../../components/MetaTags/MetaTags';
import Footer from '../../templates/Footer/Footer';

export default class Body extends React.Component {
    render() {
        const { children, bodyClass, title } = this.props;

        return (
            <div className={bodyClass}>
                <MetaTags title={title} />
                <NavBarComponent />

                {children}

                <Footer />
            </div>
        );
    }
}
