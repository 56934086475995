import { createStudentSheetAnswer, getStudentSheetAnswer, getStudentSheetById } from '../api/student-sheets';

export const DescriptionTranslations = {
    PT: 'PortugueseDescription',
    SP: 'SpanishDescription',
    CZ: 'CzechDescription',
    FI: 'FinnishDescription',
    RO: 'RomanianDescription',
    BG: 'BulgarianDescription',
    IT: 'ItalianDescription',
    HR: 'CroatianDescription',
    LT: 'LithuanianDescription',
    DE: 'GermanDescription',
    FR: 'FrenchDescription',
    US: 'EnglishDescription',
    GR: 'GreekDescription',
    TR: 'TurkishDescription',
};

export const AltDescriptionTranslations = {
    PT: 'PortugueseExerciseDescription',
    SP: 'SpanishExerciseDescription',
    CZ: 'CzechExerciseDescription',
    FI: 'FinnishExerciseDescription',
    RO: 'RomanianExerciseDescription',
    BG: 'BulgarianExerciseDescription',
    IT: 'ItalianExerciseDescription',
    HR: 'CroatianExerciseDescription',
    LT: 'LithuanianExerciseDescription',
    DE: 'GermanExerciseDescription',
    FR: 'FrenchExerciseDescription',
    US: 'EnglishExerciseDescription',
    GR: 'GreekExerciseDescription',
    TR: 'TurkishExerciseDescription',
};

export const A2Translations = {
    PT: 'PortugueseText',
    SP: 'SpanishText',
    CZ: 'CzechText',
    FI: 'FinnishText',
    RO: 'RomanianText',
    BG: 'BulgarianText',
    IT: 'ItalianText',
    HR: 'CroatianText',
    LT: 'LithuanianText',
    DE: 'GermanText',
    FR: 'FrenchText',
    US: 'EnglishText',
    GR: 'GreekText',
    TR: 'TurkishText',
};

export const C1Questions = {
    PT: 'PortugueseQuestion',
    SP: 'SpanishQuestion',
    CZ: 'CzechQuestion',
    FI: 'FinnishQuestion',
    RO: 'RomanianQuestion',
    BG: 'BulgarianQuestion',
    IT: 'ItalianQuestion',
    HR: 'CroatianQuestion',
    LT: 'LithuanianQuestion',
    DE: 'GermanQuestion',
    FR: 'FrenchQuestion',
    US: 'EnglishQuestion',
    GR: 'GreekQuestion',
    TR: 'TurkishQuestion',
};

export const DDescriptions = {
    PT: 'PortugueseImageDescription',
    SP: 'SpanishImageDescription',
    CZ: 'CzechImageDescription',
    FI: 'FinnishImageDescription',
    RO: 'RomanianImageDescription',
    BG: 'BulgarianImageDescription',
    IT: 'ItalianImageDescription',
    HR: 'CroatianImageDescription',
    LT: 'LithuanianImageDescription',
    DE: 'GermanImageDescription',
    FR: 'FrenchImageDescription',
    US: 'EnglishImageDescription',
    GR: 'GreekImageDescription',
    TR: 'TurkishImageDescription',
};

export const getExerciseDescription = (content, exercise, language, DescMap) => {
    const lngTranslation = DescMap[language];
    const enTranslation = DescMap['US'];

    const translation = content[exercise] ? content[exercise][lngTranslation] : undefined;
    const isValid = translation !== undefined;

    if (lngTranslation && isValid) {
        return content[exercise][lngTranslation];
    } else if (content[exercise]) {
        return content[exercise][enTranslation];
    } else return '';
};

export const getExerciseText = (content, exercise, language) => {
    const lngTranslation = A2Translations[language];
    const enTranslation = A2Translations['US'];

    const translation = content[exercise] ? content[exercise]['Text'][lngTranslation] : undefined;
    const isValid = translation !== undefined;

    if (lngTranslation && isValid) {
        return translation;
    } else if (content[exercise]) {
        return content[exercise]['Text'][enTranslation];
    } else return '';
};

export const getInteractiveStudentSheet = async (ssId, userId) => {
    const exercise = await getStudentSheetById(ssId);

    const disableText = localStorage.getItem('disable');
    const watchSSId = localStorage.getItem('watchStudentSheetId');
    const apiFilter = disableText ? `/${watchSSId}` : `?user=${userId}&student_sheet=${ssId}`;

    let answer = await getStudentSheetAnswer(apiFilter);

    if (!disableText && Array.isArray(answer)) {
        if (answer.length === 0) {
            answer = await createAnswer(userId, exercise.id);
        } else {
            answer = answer[0];
        }
    }

    return {
        answer: answer,
        exercise: exercise,
    };
};

const createAnswer = async (userId, ssId) => {
    let ID = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghifklmnopqrstuvwxyz0123456789';

    for (let i = 0; i < 12; i++) {
        ID += characters.charAt(Math.floor(Math.random() * 62));
    }

    const answer = await createStudentSheetAnswer(userId, ssId, ID);
    return answer;
};

export const removeSSFromStorage = async () => {
    localStorage.removeItem('answerId');
    localStorage.removeItem('code');
    localStorage.removeItem('disable');
    localStorage.removeItem('lastPage');
    localStorage.removeItem('watchStudentSheetId');
};
