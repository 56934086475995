import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import { withTranslation } from 'react-i18next';

import download from '../../assets/images/student-sheets/download.svg';
import info from '../../assets/images/student-sheets/info.svg';
import reset from '../../assets/images/student-sheets/reset.svg';
import share from '../../assets/images/student-sheets/share.svg';
import save from '../../assets/images/student-sheets/save.svg';

import './ExerciseIcons.css';

class ExerciseIcons extends React.Component {
    goToSharePage() {
        const ssId = this.props.studentSheetId;
        window.location = `/share/${ssId}`;
    }

    render() {
        const {
            t,
            exIcon,
            exTitle,
            exDescription,
            viewMode,
            activeReset,
            activeSubmit,
            activeDownload,
            resetExercise,
            submitHandler,
            downloadExercise,
        } = this.props;

        const iconStyle = { font: 'Inter', maxWidth: '250px' };

        return (
            <div className="gridIconTitle">
                <img className="iconExerciseBox" src={exIcon} alt="Exercise Icon" height="30px" />

                <p className="titleExerciseBox">
                    <b>{t(exTitle)}</b>
                </p>

                <div className="iconsWrapper">
                    {viewMode ? (
                        <></>
                    ) : (
                        <div>
                            {activeSubmit ? (
                                <Tooltip
                                    title={<h6 style={iconStyle}>{t('StudentSheetsButtons.Reset')}</h6>}
                                    arrow
                                    placement="left"
                                >
                                    <img
                                        className="iconsCursor"
                                        src={reset}
                                        alt="Reset exercise"
                                        height="40px"
                                        onClick={resetExercise}
                                    ></img>
                                </Tooltip>
                            ) : (
                                <></>
                            )}
                            {activeReset ? (
                                <Tooltip
                                    title={<h6 style={iconStyle}>{t('StudentSheetsButtons.Save')}</h6>}
                                    arrow
                                    placement="left"
                                >
                                    <img
                                        className="iconsCursor"
                                        src={save}
                                        alt="Save exercise"
                                        height="35px"
                                        onClick={submitHandler}
                                    ></img>
                                </Tooltip>
                            ) : (
                                <></>
                            )}
                            <Tooltip
                                title={<h6 style={iconStyle}>{t('StudentSheetsButtons.Share')}</h6>}
                                arrow
                                placement="left"
                            >
                                <img
                                    className="iconsCursor"
                                    src={share}
                                    alt="Share exercise"
                                    height="40px"
                                    onClick={this.goToSharePage.bind(this)}
                                ></img>
                            </Tooltip>
                            {activeDownload ? (
                                <Tooltip
                                    title={<h6 style={iconStyle}>{t('StudentSheetsButtons.Download')}</h6>}
                                    arrow
                                    placement="left"
                                >
                                    <img
                                        className="iconsCursor"
                                        src={download}
                                        alt="Download exercise"
                                        height="35px"
                                        onClick={downloadExercise}
                                    ></img>
                                </Tooltip>
                            ) : (
                                <></>
                            )}
                        </div>
                    )}
                    <Tooltip title={<h6 style={iconStyle}>{exDescription}</h6>} arrow placement="left">
                        <img className="iconsCursor" src={info} alt="Exercise description" height="35px"></img>
                    </Tooltip>
                </div>
            </div>
        );
    }
}

export default withTranslation(['translation'])(ExerciseIcons);
