import React from 'react';
import ReactGA from 'react-ga4';
import CookieConsent from 'react-cookie-consent';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { removeSSFromStorage } from '../../utils/ssUtils';

import { ReactComponent as Help } from '../../assets/images/help-icon.svg';

export default function AllPages({ location }) {
    const { t } = useTranslation();
    const pathname = location.pathname;

    const studentSheets = pathname.startsWith('/ex') || pathname.startsWith('/share');
    if (!studentSheets) {
        removeSSFromStorage();
    }

    if (typeof window.ga === 'function') {
        const page = pathname + location.search;
        ReactGA.send({ hitType: 'pageview', page: page });
    }

    return (
        <>
            {pathname !== '/' ? (
                <CookieConsent
                    location="bottom"
                    buttonText={t('PrivacyPolicy.Compliance')}
                    buttonStyle={{
                        backgroundColor: '#00868e',
                        color: 'white',
                        fontSize: '14px',
                        borderRadius: '30px',
                        padding: '10px 22px',
                    }}
                    acceptOnScroll={true}
                    containerClasses="cookie-container"
                    contentClasses="cookie-txt"
                >
                    {t('PrivacyPolicy.Disclaimer')}{' '}
                    <NavLink to="/privacy-policy">{t('PrivacyPolicy.ReadMore')}</NavLink>
                </CookieConsent>
            ) : (
                <></>
            )}

            <NavLink className="helpLink normalText" to="/frequently-asked-questions">
                <Help />
                <span className="helpLinkTxt">{t('FAQ.Help')}</span>
            </NavLink>
        </>
    );
}
