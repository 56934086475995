import { apiInfo, fetchRequest, HTTPMethod, Resource } from './api';

export const getMovies = () => {
    return fetchRequest(HTTPMethod.GET, `${apiInfo.base}/${Resource.MOVIES}/all`);
};

export const getMovieById = (id) => {
    return fetchRequest(HTTPMethod.GET, `${apiInfo.base}/${Resource.MOVIES}/${id}`);
};

export const orderMovie = (movieId) => {
    return fetchRequest(HTTPMethod.POST, `${apiInfo.base}/${Resource.MOVIES}/order/${movieId}`, null, true);
};
