import { apiInfo, fetchRequest, HTTPMethod, Resource } from './api';

export const getUserByUsername = (username) => {
    return fetchRequest(HTTPMethod.GET, `${apiInfo.base}/${Resource.USERS}/username/${username}`);
};

export const getUserByMigrationToken = (token) => {
    return fetchRequest(HTTPMethod.GET, `${apiInfo.base}/${Resource.USERS}/migrationToken/${token}`);
};

export const refreshSession = () => {
    return fetchRequest(HTTPMethod.GET, `${apiInfo.base}/${Resource.USERS}/me`, null, true);
};

export const createUser = (data) => {
    return fetchRequest(HTTPMethod.POST, `${apiInfo.base}/${Resource.USERS}`, data);
};

export const updateUserByToken = (migrationToken, data) => {
    return fetchRequest(HTTPMethod.PUT, `${apiInfo.base}/${Resource.USERS}/token/${migrationToken}`, data);
};

export const updateUser = (userId, data) => {
    return fetchRequest(HTTPMethod.PUT, `${apiInfo.base}/${Resource.USERS}/${userId}`, data, true);
};

export const updatePassword = (userId, answer, password) => {
    const data = {
        securityAnswer: answer,
        password: password,
    };

    return fetchRequest(HTTPMethod.PUT, `${apiInfo.base}/${Resource.USERS}/password/${userId}`, data);
};
