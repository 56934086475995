import React, { useEffect, useState } from 'react';

import DesktopNavBar from './DesktopNavBar';
import MobileNavBar from '../MobileNavBar/MobileNavBar';
import useWindowDimensions from '../../components/WindowDimensions/WindowDimensions';
import { removeSSFromStorage } from '../../utils/ssUtils';

import './NavBar.css';

export default function NavBarClass() {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [user, setUser] = useState(null);
    const { width } = useWindowDimensions();

    useEffect(() => {
        const token = localStorage.getItem('token');
        const userJSON = localStorage.getItem('user');
        const user = userJSON ? JSON.parse(userJSON) : null;
        if (token !== null) {
            setIsLoggedIn(true);
            setUser(user);
        }
    }, []);

    const logout = () => {
        removeSSFromStorage();

        localStorage.removeItem('token');
        localStorage.removeItem('user');

        window.location.href = '/collection';
    };

    if (width <= 1050) {
        return <MobileNavBar isUserLoggedIn={isLoggedIn} user={user} logout={logout} />;
    } else {
        return <DesktopNavBar isUserLoggedIn={isLoggedIn} user={user} logout={logout} />;
    }
}
