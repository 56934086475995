import { ReactComponent as WindowsIcon } from '../../assets/images/windows.svg';
import { ReactComponent as AppleIcon } from '../../assets/images/apple.svg';
import { ReactComponent as LaptopIcon } from '../../assets/images/laptop.svg';
import { useTranslation } from 'react-i18next';
import { lang } from '../../utils/utils';

export default function MinimumRequirementsModal() {
    const { i18n, t } = useTranslation();

    return (
        <div className="MRcontainer">
            <div className="MRheader">
                <div className="iconContainer">
                    <LaptopIcon />
                </div>
            </div>
            <div className="MRcontent">
                <div className="descriptionTitle">
                    <h3 lang={lang[i18n.language]}>{t('Player.MinimumRequirements')}</h3>
                </div>
                <div className="descriptionContent">
                    <div className="row">
                        <WindowsIcon />
                        <p>{t('Player.Windows')}</p>
                    </div>
                    <div className="row">
                        <AppleIcon />
                        <p>{t('Player.Mac')}</p>
                    </div>
                </div>
            </div>
            <div className="MRfooter"></div>
        </div>
    );
}
