import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    components: {
        MuiPickersCalendarHeader: {
            styleOverrides: {
                label: {
                    fontSize: '1.6rem',
                },
            },
        },
        MuiDayCalendar: {
            styleOverrides: {
                weekDayLabel: {
                    fontSize: '1.2rem',
                },
            },
        },
        MuiPickersYear: {
            styleOverrides: {
                yearButton: {
                    fontSize: '1.4rem',
                },
            },
        },
        MuiPickersDay: {
            styleOverrides: {
                root: {
                    fontSize: '1.2em',
                },
            },
        },
    },
    palette: {
        primary: {
            main: '#D8B74B',
        },
        secondary: {
            main: '#00868e',
        },
    },
    typography: {
        fontSize: 20,
        fontFamily: `'Inter', Bahnschrift, 'Source Sans Pro', 'D-DIN', sans-serif, -apple-system, BlinkMacSystemFont`,
    },
});

export default theme;
