import React from 'react';
import { withTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import ModalRegisterStudent from './ModalRegisterStudent';
import ModalRegisterTeacher from './ModalRegisterTeacher';
import NavBarComponent from '../../templates/NavBar/NavBar';
import MetaTags from '../../components/MetaTags/MetaTags';

import background from '../../assets/images/aniki-bobo.webp';

class SignUpClass extends React.Component {
    constructor() {
        super();
        this.state = {
            selected: null,
        };

        this.setSelectedModal = this.setSelectedModal.bind(this);
    }

    setSelectedModal(selected) {
        this.setState({
            selected: selected,
        });
    }

    render() {
        const { t } = this.props;
        const { selected } = this.state;

        return (
            <div className="App" style={{ backgroundImage: `url(${background})` }}>
                <MetaTags title={`${t('SignInUp.Register')} | CinEd`} />
                <NavBarComponent />

                <div className="signUpForm authForm">
                    <div className="signUpFormWrapper">
                        <div className="signUpStudentTeacher">
                            <p className="signUpStudentTitle signUpTitle h2Text">{t('SignInUp.Student')}</p>

                            <div className="buttonSignUpWrapper">
                                <button
                                    className="submitRegistration smallText"
                                    onClick={() => this.setSelectedModal('cs')}
                                >
                                    {t('SignInUp.CreateNewAccount')}
                                </button>
                            </div>
                        </div>

                        <div className="signUpDivision" />

                        <div className="signUpStudentTeacher">
                            <div className="wrapperTeacher">
                                <div>
                                    <p className="signUpTeacherTitle signUpTitle h2Text">{t('SignInUp.Im')} ...</p>
                                </div>
                                <div className="signUpRoleWrapper">
                                    <p className="signUpRole bigText">{t('SignInUp.ImTeacher')}</p>
                                    <p className="signUpRole bigText">{t('SignInUp.ImTrainee')}</p>
                                    <p className="signUpRole bigText">{t('SignInUp.ImInstitution')}</p>
                                </div>
                            </div>

                            <div className="buttonSignUpWrapper">
                                <button
                                    className="submitRegistration smallText"
                                    onClick={() => this.setSelectedModal('ct')}
                                >
                                    {t('SignInUp.CreateNewAccount')}
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="btnBackWrapper">
                        <NavLink to="/login" className="btnBackLink">
                            <button className="btnRegistration smallText">{t('SignInUp.Close')}</button>
                        </NavLink>
                    </div>
                </div>

                <div className="signInUpPlaceholder" />

                <ModalRegisterTeacher
                    open={selected === 'ct' ? true : false}
                    closeModal={() => this.setState({ selected: null })}
                />

                <ModalRegisterStudent
                    open={selected === 'cs' ? true : false}
                    closeModal={() => this.setState({ selected: null })}
                />
            </div>
        );
    }
}

export default withTranslation(['translation'])(SignUpClass);
