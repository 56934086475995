import { apiInfo, fetchRequest, HTTPMethod, Resource } from './api';

export const getMovieOrders = () => {
    return fetchRequest(HTTPMethod.GET, `${apiInfo.base}/${Resource.MOVIE_ORDERS}`, null, true);
};

export const isMovieOrdered = (movieId, type) => {
    return fetchRequest(
        HTTPMethod.GET,
        `${apiInfo.base}/${Resource.MOVIE_ORDERS}/check/${movieId}?type=${type}`,
        null,
        true,
    );
};
