import { apiInfo, fetchRequest, HTTPMethod, Resource } from './api';

export const uploadImage = (imgData) => {
    const formData = new FormData();
    formData.append('files', imgData);

    return fetchRequest(HTTPMethod.POST, `${apiInfo.base}/${Resource.UPLOAD}`, formData, false, true);
};

export const uploadMultipleImages = (imgArray) => {
    const formData = new FormData();
    for (let i = 0; i < imgArray.length; i++) {
        formData.append('files', imgArray[i]);
    }

    return fetchRequest(HTTPMethod.POST, `${apiInfo.base}/${Resource.UPLOAD}`, formData, false, true);
};
