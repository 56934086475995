import React from 'react';
import i18next from 'i18next';
import { NavLink, Redirect } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import Body from '../../../templates/Body/Body';
import BodyExercise from '../../BodyExercise/BodyExercise';
import ExerciseIcons from '../../BodyExercise/ExerciseIcons';
import Loading from '../../../components/Loading/Loading';
import { updateStudentSheetAnswer } from '../../../api/student-sheets';
import { DescriptionTranslations, getExerciseDescription } from '../../../utils/ssUtils';
import { getInteractiveStudentSheet } from '../../../utils/ssUtils';

import icon from '../../../assets/images/student-sheets/letterActivity.svg';
import './ExC12.css';

class ExC12Class extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            ex: null,
            loading: true,
            error: false,
            ExerciseC1_2_Greetings: '',
            ExerciseC1_2_Body: '',
            ExerciseC1_2_Closing: '',
            viewMode: false,
        };
    }

    componentDidMount = async () => {
        localStorage.setItem('lastPage', '/exC12');
        const ssId = this.props.match.params.id;
        const user = JSON.parse(localStorage.getItem('user'));

        let answer, exercise;
        try {
            const response = await getInteractiveStudentSheet(ssId, user.id);
            exercise = response.exercise;
            answer = response.answer;
        } catch (error) {
            console.error('An error occurred: ', error);
            this.setState({ error: true });
            return;
        }

        const disableText = localStorage.getItem('disable');
        this.updateExercise(answer, disableText);

        this.setState({
            loading: false,
            ex: exercise,
            answerId: answer?.id,
        });
    };

    updateExercise = (answer, disabled) => {
        this.setState({
            ExerciseC1_2_Greetings: answer.ExerciseC1_2_Greetings,
            ExerciseC1_2_Body: answer.ExerciseC1_2_Body,
            ExerciseC1_2_Closing: answer.ExerciseC1_2_Closing,
        });

        if (disabled) {
            this.setState({ viewMode: true });
        } else {
            localStorage.setItem('code', answer?.Code);
        }
    };

    changeHandler = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    };

    submitHandler = async (e) => {
        e.preventDefault();
        this.setState({ loading: true });

        const answerExercise = {
            ExerciseC1_2_Greetings: this.state.ExerciseC1_2_Greetings,
            ExerciseC1_2_Body: this.state.ExerciseC1_2_Body,
            ExerciseC1_2_Closing: this.state.ExerciseC1_2_Closing,
        };

        try {
            await updateStudentSheetAnswer(this.state.answerId, answerExercise);
        } catch (error) {
            console.log(error);
            return;
        }

        this.setState({ loading: false });
    };

    resetExercise = async (e) => {
        e.preventDefault();
        this.setState({ loading: true });

        const answerExercise = {
            ExerciseC1_2_Greetings: '',
            ExerciseC1_2_Body: '',
            ExerciseC1_2_Closing: '',
        };

        try {
            await updateStudentSheetAnswer(this.state.answerId, answerExercise);
        } catch (error) {
            console.log(error);
            return;
        }

        this.setState({
            ...answerExercise,
            loading: false,
        });
    };

    render() {
        const { t } = this.props;

        const checkToken = localStorage.getItem('token');
        if (!checkToken) {
            return <Redirect to="/no-account" />;
        }

        if (this.state.error) {
            return <Redirect to="/page-not-found" />;
        }

        if (this.state.loading) {
            return (
                <Body bodyClass="content" title="C1.2 Exercise | CinEd">
                    <Loading />
                </Body>
            );
        }

        const exercise = this.state.ex;
        const { ExerciseC1_2_Greetings, ExerciseC1_2_Body, ExerciseC1_2_Closing } = this.state;

        const description = getExerciseDescription(this.state.ex, 'C1_2', i18next.language, DescriptionTranslations);

        return (
            <BodyExercise
                selectedOption="exC11"
                studentSheetId={this.props.match.params.id}
                movieTitle={exercise?.MovieTitle}
                pageTitle={`C1.2 Exercise | ${exercise?.MovieTitle} | CinEd`}
                viewMode={this.state.viewMode}
                interactiveEx={false}
                prevLink={`/exB2/${this.props.match.params.id}`}
                nextLink={`/exC21/${this.props.match.params.id}`}
            >
                <ExerciseIcons
                    studentSheetId={this.props.match.params.id}
                    exIcon={icon}
                    exTitle="StudentSheetsLeftMenu.LearnAndImagine"
                    exDescription={description}
                    viewMode={this.state.viewMode}
                    resetExercise={this.resetExercise}
                    submitHandler={this.submitHandler}
                    activeReset
                    activeSubmit
                />

                <div className="letterBoxForm">
                    <div className="fieldsLetter">
                        <textarea
                            name="ExerciseC1_2_Greetings"
                            value={ExerciseC1_2_Greetings}
                            disabled={this.state.viewMode}
                            onChange={this.changeHandler}
                            className="letterGreetings"
                            placeholder={t('StudentSheetsContent.FirstField')}
                        />
                    </div>

                    <div className="fieldsLetter">
                        <textarea
                            name="ExerciseC1_2_Body"
                            value={ExerciseC1_2_Body}
                            disabled={this.state.viewMode}
                            onChange={this.changeHandler}
                            className="letterBody"
                            placeholder={t('StudentSheetsContent.SecondField')}
                        />
                    </div>

                    <div className="fieldsLetterLeft">
                        <textarea
                            name="ExerciseC1_2_Closing"
                            value={ExerciseC1_2_Closing}
                            disabled={this.state.viewMode}
                            onChange={this.changeHandler}
                            className="letterClosing"
                            placeholder={t('StudentSheetsContent.LastField')}
                        />
                    </div>
                </div>

                <div className="exerciseButtonsGroup">
                    <div className="buttonsGroupWrapper">
                        <div>
                            <NavLink to={`/exC11/${this.props.match.params.id}`}>
                                <button className="exerciseButtonNotSelected">1</button>
                            </NavLink>
                        </div>

                        <div>
                            <NavLink to={`/exC12/${this.props.match.params.id}`}>
                                <button className="exerciseButtonSelected">2</button>
                            </NavLink>
                        </div>
                    </div>
                </div>
            </BodyExercise>
        );
    }
}

export default withTranslation(['translation'])(ExC12Class);
