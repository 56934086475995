import React from 'react';
import i18next from 'i18next';
import { Redirect } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import Body from '../../templates/Body/Body';
import BodyExercise from '../BodyExercise/BodyExercise';
import ExerciseIcons from '../BodyExercise/ExerciseIcons';
import Loading from '../../components/Loading/Loading';
import { BASE_URL } from '../../utils/globals';
import { getStudentSheetById } from '../../api/student-sheets';
import { DescriptionTranslations, getExerciseDescription } from '../../utils/ssUtils';

import icon from '../../assets/images/student-sheets/readActivity.svg';
import map from '../../assets/images/map/map.png';
import './ExB1.css';

const AnikiBoboTitle = 'aniki bobo';

class ExB1Class extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            ex: null,
            loading: true,
            error: false,
            viewMode: false,
        };
    }

    componentDidMount = async () => {
        const ssId = this.props.match.params.id;
        localStorage.setItem('lastPage', '/exB1');

        let response;
        try {
            response = await getStudentSheetById(ssId);
        } catch (error) {
            console.error('An error occurred: ', error);
            this.setState({ error: true });
            return;
        }

        this.setState({
            loading: false,
            ex: response,
        });

        if (localStorage.getItem('disable')) {
            this.setState({ viewMode: true });
        }
    };

    render() {
        const { t } = this.props;

        const checkToken = localStorage.getItem('token');
        if (!checkToken) {
            return <Redirect to="/no-account" />;
        }

        if (this.state.error) {
            return <Redirect to="/page-not-found" />;
        }

        if (this.state.loading) {
            return (
                <Body bodyClass="content" title="B1 Exercise | CinEd">
                    <Loading />
                </Body>
            );
        }

        const exercise = this.state.ex;
        const normalizedTitle = exercise?.MovieTitle?.toLowerCase().replace(/[ó]/g, 'o');

        const description = getExerciseDescription(this.state.ex, 'B1', i18next.language, DescriptionTranslations);

        return (
            <BodyExercise
                selectedOption="exB1"
                studentSheetId={this.props.match.params.id}
                movieTitle={exercise?.MovieTitle}
                pageTitle={`B1 Exercise | ${exercise?.MovieTitle} | CinEd`}
                viewMode={this.state.viewMode}
                interactiveEx={false}
                prevLink={`/exA3/${this.props.match.params.id}`}
                nextLink={`/exB2/${this.props.match.params.id}`}
            >
                <ExerciseIcons
                    studentSheetId={this.props.match.params.id}
                    exIcon={icon}
                    exTitle="StudentSheetsLeftMenu.FilmSeveralTitles"
                    exDescription={description}
                    viewMode={this.state.viewMode}
                />

                <div className="exerciseB1Container">
                    <div className="titleFlagStructure">
                        <p className="movieB1">
                            <b>{exercise?.MovieTitle}</b>
                        </p>
                        <img className="flagB1" src={`${BASE_URL}${exercise?.B1?.CountryFlag?.url}`} alt="Posters" />
                    </div>
                    <div className="translationsStructure">
                        <div className="translationMapWrapper">
                            {normalizedTitle === AnikiBoboTitle ? (
                                <div className="titleTranslations">{description}</div>
                            ) : (
                                <div className="titleTranslations">
                                    <p>
                                        <b>{t('StudentSheetsContent.Portuguese')}:</b> {exercise?.B1?.PortugueseTitle}
                                    </p>
                                    <p>
                                        <b>{t('StudentSheetsContent.Spanish')}:</b> {exercise?.B1?.SpanishTitle}
                                    </p>
                                    <p>
                                        <b>{t('StudentSheetsContent.French')}:</b> {exercise?.B1?.FrenchTitle}
                                    </p>
                                    <p>
                                        <b>{t('StudentSheetsContent.English')}:</b> {exercise?.B1?.EnglishTitle}
                                    </p>
                                    <p>
                                        <b>{t('StudentSheetsContent.Czech')}:</b> {exercise?.B1?.CzechTitle}
                                    </p>
                                    <p>
                                        <b>{t('StudentSheetsContent.Romanian')}:</b> {exercise?.B1?.RomanianTitle}
                                    </p>
                                    <p>
                                        <b>{t('StudentSheetsContent.Bulgarian')}:</b> {exercise?.B1?.BulgarianTitle}
                                    </p>
                                    <p>
                                        <b>{t('StudentSheetsContent.Italian')}:</b> {exercise?.B1?.ItalianTitle}
                                    </p>
                                    <p>
                                        <b>{t('StudentSheetsContent.Finnish')}:</b> {exercise?.B1?.FinnishTitle}
                                    </p>
                                    <p>
                                        <b>{t('StudentSheetsContent.German')}:</b> {exercise?.B1?.GermanTitle}
                                    </p>
                                    <p>
                                        <b>{t('StudentSheetsContent.Lithuanian')}:</b> {exercise?.B1?.LithuanianTitle}
                                    </p>
                                    <p>
                                        <b>{t('StudentSheetsContent.Croatian')}:</b> {exercise?.B1?.CroatianTitle}
                                    </p>
                                    {exercise?.B1?.GreekTitle ? (
                                        <p>
                                            <b>{t('StudentSheetsContent.Greek')}:</b> {exercise?.B1?.GreekTitle}
                                        </p>
                                    ) : (
                                        <></>
                                    )}
                                    {exercise?.B1?.TurkishTitle ? (
                                        <p>
                                            <b>{t('StudentSheetsContent.Turkish')}:</b> {exercise?.B1?.TurkishTitle}
                                        </p>
                                    ) : (
                                        <></>
                                    )}
                                </div>
                            )}
                            <img src={map} className="mapB1" alt="numbers" />
                        </div>
                    </div>
                </div>
            </BodyExercise>
        );
    }
}

export default withTranslation(['translation'])(ExB1Class);
