import React from 'react';

export default function ProjectIcon({ fill }) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="97" height="97" viewBox="0 0 97 97" fill="none">
            <g id="CINED_Homepage_Icons-02 2">
                <g id="Group">
                    <path
                        id="Vector"
                        d="M33.9501 92.15C33.9501 93.44 34.4601 94.67 35.3701 95.58C36.2801 96.49 37.5101 97 38.8001 97H58.2001C59.4901 97 60.7201 96.49 61.6301 95.58C62.5401 94.67 63.0501 93.44 63.0501 92.15V87.3H33.9501V92.15Z"
                        fill={fill}
                    />
                    <path
                        id="Vector_2"
                        d="M59.82 37.72C57.74 37.72 56.05 39.41 56.05 41.49V45.26H59.82C61.9 45.26 63.59 43.57 63.59 41.49C63.59 39.41 61.9 37.72 59.82 37.72Z"
                        fill={fill}
                    />
                    <path
                        id="Vector_3"
                        d="M37.18 37.72C35.1 37.72 33.41 39.41 33.41 41.49C33.41 43.57 35.1 45.26 37.18 45.26H40.95V41.49C40.96 39.41 39.27 37.72 37.18 37.72Z"
                        fill={fill}
                    />
                    <path
                        id="Vector_4"
                        d="M72.5101 9.94C66.1401 3.58 57.5 0 48.5 0C39.5 0 30.86 3.58 24.49 9.94C18.12 16.3 14.55 24.94 14.55 33.95C14.55 45.49 20.32 55.63 29.1 61.79V72.75C29.1 74.04 29.61 75.27 30.52 76.18C31.43 77.09 32.66 77.6 33.9501 77.6H63.05C64.34 77.6 65.5701 77.09 66.4801 76.18C67.3901 75.27 67.9001 74.04 67.9001 72.75V61.79C76.68 55.63 82.45 45.49 82.45 33.95C82.45 24.95 78.87 16.31 72.5101 9.94ZM59.82 49.04H56.05V77.6H52.28V49.04H44.7401V77.6H40.9701V49.04H37.2001C33.0301 49.04 29.66 45.66 29.66 41.5C29.66 37.34 33.0401 33.96 37.2001 33.96C41.3601 33.96 44.7401 37.34 44.7401 41.5V45.27H52.28V41.5C52.28 37.33 55.66 33.96 59.82 33.96C63.98 33.96 67.3601 37.34 67.3601 41.5C67.3601 45.66 63.98 49.04 59.82 49.04Z"
                        fill={fill}
                    />
                </g>
            </g>
        </svg>
    );
}
