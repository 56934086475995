import React from 'react';
import { withTranslation } from 'react-i18next';
import Dialog from '@mui/material/Dialog';

class Modal extends React.Component {
    render() {
        const {
            children,
            t,
            open,
            closeModal,
            title,
            titleClass,
            closeTranslation,
            imgSrc,
            imgAlt,
            link,
            maxWidth,
            fullWidth,
        } = this.props;

        return (
            <Dialog
                scroll="body"
                onClose={(event, reason) => {
                    if (reason !== 'backdropClick') {
                        closeModal();
                    }
                }}
                open={open}
                maxWidth={maxWidth ? maxWidth : 'sm'}
                fullWidth={fullWidth ? true : false}
            >
                {title ? (
                    <div className="modalHeader">
                        <button type="button" className="close" onClick={() => closeModal()}>
                            &times;
                        </button>

                        <div className="structureGridModal">
                            {imgSrc ? (
                                <div className="modalImgWrapper">
                                    <img className="modalLogoHeader" src={imgSrc} alt={imgAlt} />
                                </div>
                            ) : (
                                <></>
                            )}

                            <h2 className={titleClass}>{title}</h2>

                            {link ? (
                                <a href={link} target="_blank" rel="noopener noreferrer">
                                    <button className="modalButton detailsText">
                                        {t('Collection.HeaderMoreInfo')}
                                    </button>
                                </a>
                            ) : (
                                <></>
                            )}
                        </div>
                    </div>
                ) : (
                    <></>
                )}

                {children}

                <div className="modalFooter">
                    <div className="modalCloseBtn">
                        <button type="button" className="btn btn-default" onClick={() => closeModal()}>
                            {t(closeTranslation)}
                        </button>
                    </div>
                </div>
            </Dialog>
        );
    }
}

export default withTranslation(['translation'])(Modal);
