import React from 'react';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import Shape from '../../assets/images/cined-shape.svg';
import Down from '../../assets/images/collection/arrowDown.svg';

class PageHeader extends React.Component {
    render() {
        const { t, isMovie, imgSrc, title, body, titleTranslation, link, anchorLink } = this.props;

        return (
            <div className="container gridShape">
                <img className="cinedShape" src={Shape} alt="CinEd Shape" />

                <div className="headerContentWrapper">
                    <div className="collectionStructure">
                        <div className="headerPhotogramContainer">
                            <Link to={link}>
                                <img className="headerPhotogram" src={imgSrc} alt="Favorite Photogram" />
                            </Link>
                        </div>

                        <div className="collectionSubStructure">
                            <h2 className={isMovie ? 'headerTitle h3Text' : 'headerTitleNew h4Text'}>{title}</h2>

                            {titleTranslation ? (
                                <span className="headerTitleTranslation normalText">{titleTranslation}</span>
                            ) : (
                                <></>
                            )}

                            <div className="headerBody">{body}</div>

                            <Link className="moreInfoHeader normalText" to={link}>
                                + {t(isMovie ? 'Collection.HeaderMoreInfo' : 'NewsEvents.MoreInfo')}
                            </Link>
                        </div>
                    </div>
                </div>

                <a href={anchorLink} className="seeMoreContainer">
                    {isMovie ? <span className="seeMoreTxt normalText">{t('Collection.HeaderSeeMore')}</span> : <></>}

                    <img className="arrowDown" src={Down} alt="Arrow Down" />
                </a>
            </div>
        );
    }
}

export default withTranslation(['translation'])(PageHeader);
