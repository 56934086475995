import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import LanguageSelector from '../../components/LanguageSelector/LanguageSelector';
import { getPlayer } from '../../utils/utils';
import DropdownPopup from '../../components/Popup/DropdownPopup';
import CinedLogo from '../../assets/images/logo.svg';
import DownloadIcon from '@mui/icons-material/GetAppRounded';
import MenuIcon from '../../assets/images/menuburguer.svg';
import MenuCloseIcon from '../../assets/images/menuClose.svg';
import './MobileNavBar.css';

const NavBarOptions = [
    {
        title: 'NavBar.Collection',
        link: '/collection',
    },
    {
        title: 'NavBar.TeachingResources',
        link: '/teaching-with-cined',
    },
    {
        title: 'NavBar.Project',
        link: '/about-project',
    },
    {
        title: 'NavBar.EventsNews',
        link: '/news-events',
    },
];

export default function MobileNavBar(props) {
    const [open, setOpen] = useState(false);
    const [openPlayerPopup, setOpenPlayerPopup] = useState(false);
    const { support } = getPlayer();
    const { t } = useTranslation();
    const isStudent = props.user?.userRole?.toLowerCase() !== 'student';

    const matchAuthPages = (match, location) => {
        if (location.pathname === '/login') {
            return true;
        } else if (location.pathname === '/registration') {
            return true;
        }

        return false;
    };

    return (
        <div>
            <DropdownPopup open={openPlayerPopup} closeModal={() => setOpenPlayerPopup(false)} />
            <div className="mobileNavbar">
                {open ? (
                    <img
                        className="menuCloseNav"
                        src={MenuCloseIcon}
                        alt="Close Menu Icon"
                        onClick={() => setOpen(false)}
                    />
                ) : (
                    <img className="menuNav" src={MenuIcon} alt="Open Menu Icon" onClick={() => setOpen(true)} />
                )}

                <a className="mobileLogo" href="/">
                    <img src={CinedLogo} className="logoNav" alt="logoNav" />
                </a>

                {open ? (
                    <div className="backgroundNavBarMobile">
                        <div className="mobileNavContent">
                            {NavBarOptions.map((opt, idx) => (
                                <div key={idx}>
                                    <NavLink className="leftLink" activeClassName="activeLink" to={opt.link}>
                                        {t(opt.title)}
                                    </NavLink>

                                    <div className="fieldsDivisionNav" />
                                </div>
                            ))}

                            {props.isUserLoggedIn ? (
                                <>
                                    <div>
                                        <NavLink className="leftLink" activeClassName="activeLink" to="/user/profile">
                                            {t('NavBar.MyProfile')}
                                        </NavLink>

                                        <div className="fieldsDivisionNav" />
                                    </div>

                                    {isStudent ? (
                                        <div>
                                            <NavLink
                                                className="leftLink"
                                                activeClassName="activeLink"
                                                to="/user/orders"
                                            >
                                                {t('NavBar.Orders')}
                                            </NavLink>

                                            <div className="fieldsDivisionNav" />
                                        </div>
                                    ) : (
                                        <></>
                                    )}

                                    {isStudent && support ? (
                                        <div>
                                            <span
                                                className="leftLink downloadAnchor"
                                                onClick={() => setOpenPlayerPopup(true)}
                                            >
                                                <span>{t('NavBar.VideoPlayer')}</span> <DownloadIcon />
                                            </span>

                                            <div className="fieldsDivisionNav" />
                                        </div>
                                    ) : (
                                        <></>
                                    )}

                                    <div>
                                        <span className="leftLink" onClick={props.logout}>
                                            {t('NavBar.SignOut')}
                                        </span>

                                        <div className="fieldsDivisionNav" />
                                    </div>
                                </>
                            ) : (
                                <div>
                                    <NavLink
                                        className="leftLink"
                                        activeClassName="activeLink"
                                        isActive={(match, location) => matchAuthPages(match, location)}
                                        to="/login"
                                    >
                                        {t('NavBar.SignIn')}
                                    </NavLink>

                                    <div className="fieldsDivisionNav" />
                                </div>
                            )}
                            <div>
                                <a
                                    href="https://atlas.cined.eu/"
                                    className="leftLink"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {t('NavBar.CinedAtlas')}
                                </a>

                                <div className="fieldsDivisionNav" />
                            </div>

                            <div>
                                <NavLink className="leftLink" activeClassName="activeLink" to="/contacts">
                                    {t('NavBar.Contacts')}
                                </NavLink>

                                <div className="fieldsDivisionNav" />
                            </div>

                            <LanguageSelector navbar={true} />
                        </div>
                    </div>
                ) : (
                    <></>
                )}
            </div>
            <div className="navbar-placeholder" />
        </div>
    );
}
