import React from 'react';
import i18next from 'i18next';
import { NavLink, Redirect } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import Body from '../../../templates/Body/Body';
import BodyExercise from '../../BodyExercise/BodyExercise';
import ExerciseIcons from '../../BodyExercise/ExerciseIcons';
import Loading from '../../../components/Loading/Loading';
import { C1Questions, DescriptionTranslations, getExerciseDescription } from '../../../utils/ssUtils';
import { getInteractiveStudentSheet } from '../../../utils/ssUtils';
import { updateStudentSheetAnswer } from '../../../api/student-sheets';

import icon from '../../../assets/images/student-sheets/questionsActivity.svg';
import './ExC11.css';

class ExC11Class extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            ex: null,
            loading: true,
            error: false,
            myIndex: 0,
            answerId: '',
            ExerciseC1_1_Answer1: '',
            ExerciseC1_1_Answer2: '',
            ExerciseC1_1_Answer3: '',
            ExerciseC1_1_Answer4: '',
            ExerciseC1_1_Answer5: '',
            viewMode: false,
        };
        this.selectTab = this.selectTab.bind(this);
    }

    componentDidMount = async () => {
        localStorage.setItem('lastPage', '/exC11');
        const ssId = this.props.match.params.id;
        const user = JSON.parse(localStorage.getItem('user'));

        let answer, exercise;
        try {
            const response = await getInteractiveStudentSheet(ssId, user.id);
            exercise = response.exercise;
            answer = response.answer;
        } catch (error) {
            console.error('An error occurred: ', error);
            this.setState({ error: true });
            return;
        }

        const disableText = localStorage.getItem('disable');
        this.updateExercise(answer, disableText);

        this.setState({
            loading: false,
            ex: exercise,
            answerId: answer?.id,
        });
    };

    updateExercise = (answer, disabled) => {
        this.setState({
            ExerciseC1_1_Answer1: answer.ExerciseC1_1_Answer1,
            ExerciseC1_1_Answer2: answer.ExerciseC1_1_Answer2,
            ExerciseC1_1_Answer3: answer.ExerciseC1_1_Answer3,
            ExerciseC1_1_Answer4: answer.ExerciseC1_1_Answer4,
            ExerciseC1_1_Answer5: answer.ExerciseC1_1_Answer5,
        });

        if (disabled) {
            this.setState({ viewMode: true });
        } else {
            localStorage.setItem('code', answer?.Code);
        }
    };

    getExerciseQuestions = (content, idx) => {
        const lngTranslation = C1Questions[i18next.language];
        const enTranslation = C1Questions['US'];

        const translation = content.C1_1?.questions ? content.C1_1?.questions[idx][lngTranslation] : undefined;
        const isValid = translation !== undefined && translation !== '';

        if (lngTranslation && isValid) {
            return translation;
        } else if (content.C1_1) {
            return content.C1_1?.questions[idx][enTranslation];
        } else return '';
    };

    changeHandler = (e) => {
        const exerciseNo = this.state.myIndex + 1;
        const exerciseKey = `ExerciseC1_1_Answer${exerciseNo}`;

        this.setState({ [exerciseKey]: e.target.value });
    };

    submitHandler = async (e) => {
        e.preventDefault();
        this.setState({ loading: true });

        const answerExercise = {
            ExerciseC1_1_Answer1: this.state.ExerciseC1_1_Answer1,
            ExerciseC1_1_Answer2: this.state.ExerciseC1_1_Answer2,
            ExerciseC1_1_Answer3: this.state.ExerciseC1_1_Answer3,
            ExerciseC1_1_Answer4: this.state.ExerciseC1_1_Answer4,
            ExerciseC1_1_Answer5: this.state.ExerciseC1_1_Answer5,
        };

        try {
            await updateStudentSheetAnswer(this.state.answerId, answerExercise);
        } catch (error) {
            console.log(error);
            return;
        }

        this.setState({ loading: false });
    };

    resetExercise = async (e) => {
        e.preventDefault();
        this.setState({ loading: true });

        const answerExercise = {
            ExerciseC1_1_Answer1: '',
            ExerciseC1_1_Answer2: '',
            ExerciseC1_1_Answer3: '',
            ExerciseC1_1_Answer4: '',
            ExerciseC1_1_Answer5: '',
        };

        try {
            await updateStudentSheetAnswer(this.state.answerId, answerExercise);
        } catch (error) {
            console.log(error);
            return;
        }

        this.setState({
            ...answerExercise,
            loading: false,
        });
    };

    selectTab(idx) {
        this.setState({ myIndex: idx });
    }

    render() {
        const { t } = this.props;

        const checkToken = localStorage.getItem('token');
        if (!checkToken) {
            return <Redirect to="/no-account" />;
        }

        if (this.state.error) {
            return <Redirect to="/page-not-found" />;
        }

        if (this.state.loading) {
            return (
                <Body bodyClass="content" title="C1.1 Exercise | CinEd">
                    <Loading />
                </Body>
            );
        }

        const { myIndex, ex } = this.state;
        const description = getExerciseDescription(ex, 'C1_1', i18next.language, DescriptionTranslations);
        const question = this.getExerciseQuestions(ex, myIndex);
        const questionsList = ex.C1_1.questions;

        return (
            <BodyExercise
                selectedOption="exC11"
                studentSheetId={this.props.match.params.id}
                movieTitle={ex?.MovieTitle}
                pageTitle={`C1.1 Exercise | ${ex?.MovieTitle} | CinEd`}
                viewMode={this.state.viewMode}
                interactiveEx={true}
                prevLink={`/exB2/${this.props.match.params.id}`}
                nextLink={`/exC21/${this.props.match.params.id}`}
            >
                <ExerciseIcons
                    studentSheetId={this.props.match.params.id}
                    exIcon={icon}
                    exTitle="StudentSheetsLeftMenu.LearnAndImagine"
                    exDescription={description}
                    viewMode={this.state.viewMode}
                    resetExercise={this.resetExercise}
                    submitHandler={this.submitHandler}
                    activeReset
                    activeSubmit
                />

                <div className="exerciseFlexWrapper c11">
                    <div className="buttonsWrapper">
                        {questionsList.map((q, idx) => (
                            <button
                                key={q.id}
                                className={myIndex === idx ? 'questionButtonSelected' : 'questionButtonNotSelected'}
                                onClick={() => this.selectTab(idx)}
                            >
                                {idx + 1}
                            </button>
                        ))}
                    </div>

                    <div className="questionBox">
                        <p className="question">{question}</p>
                        <div className="questionSeparator" />

                        <textarea
                            className="answerBox"
                            disabled={this.state.viewMode}
                            placeholder={t('StudentSheetsContent.C11')}
                            value={this.state[`ExerciseC1_1_Answer${myIndex + 1}`] || ''}
                            name={`ExerciseC1_1_Answer${myIndex + 1}`}
                            onChange={this.changeHandler.bind(this)}
                        />
                    </div>
                </div>

                <div className="exerciseButtonsGroup">
                    <div className="buttonsGroupWrapper">
                        <div>
                            <NavLink to={`/exC11/${this.props.match.params.id}`}>
                                <button className="exerciseButtonSelected">1</button>
                            </NavLink>
                        </div>

                        <div>
                            <NavLink to={`/exC12/${this.props.match.params.id}`}>
                                <button className="exerciseButtonNotSelected">2</button>
                            </NavLink>
                        </div>
                    </div>
                </div>
            </BodyExercise>
        );
    }
}

export default withTranslation(['translation'])(ExC11Class);
