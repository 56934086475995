import { apiInfo, fetchRequest, HTTPMethod, Resource } from './api';

export const getStudentSheetAnswer = (filter = '') => {
    return fetchRequest(HTTPMethod.GET, `${apiInfo.base}/${Resource.SHEETS_ANSWERS}${filter}`);
};

export const getStudentSheetById = (ssId) => {
    return fetchRequest(HTTPMethod.GET, `${apiInfo.base}/${Resource.STUDENT_SHEETS}/${ssId}`, null, true);
};

export const createStudentSheetAnswer = (userId, ssId, code) => {
    const data = {
        user: userId,
        student_sheet: ssId,
        Code: code,
    };

    return fetchRequest(HTTPMethod.POST, `${apiInfo.base}/${Resource.SHEETS_ANSWERS}`, data);
};

export const updateStudentSheetAnswer = (id, data) => {
    return fetchRequest(HTTPMethod.PUT, `${apiInfo.base}/${Resource.SHEETS_ANSWERS}/${id}`, data);
};
