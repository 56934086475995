import React from 'react';
import Slider from 'react-slick';
import i18next from 'i18next';
import { withTranslation } from 'react-i18next';

import Arrow from '../../components/Arrow/Arrow';
import { Translations } from '../../utils/utils';
import { getTestimonials } from '../../api/testimonials';

import ArrowLeft from '../../assets/images/about-project/leftArrow.svg';
import ArrowRight from '../../assets/images/about-project/rightArrow.svg';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './Testimonials.css';

const Prefix = 'testimonials-translations.';
const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <Arrow imgSrc={ArrowLeft} imgAlt="Arrow Left" />,
    nextArrow: <Arrow imgSrc={ArrowRight} imgAlt="Arrow Right" />,
    autoplay: true,
    autoplaySpeed: 10000,
};

class Testimonials extends React.Component {
    constructor() {
        super();

        this.state = {
            currentTestimonialIndex: 0,
            testimonials: [],
            language: '',
        };
    }

    async componentDidMount() {
        const language = Translations[i18next.language];
        this.setState({ language: Prefix + language });

        let testimonials;
        try {
            testimonials = await getTestimonials();
        } catch (error) {
            console.error('An error occurred: ', error);
            return;
        }

        this.setState({
            testimonials: testimonials,
        });
    }

    componentDidUpdate(prevProps) {
        if (this.props.t !== prevProps.t) {
            const language = Translations[i18next.language];
            this.setState({ language: Prefix + language });
        }
    }

    getTestimonialTranslation(entry) {
        const translations = entry.Translations;
        let testimonial = '';

        if (!translations || this.state.language === 'US') {
            testimonial = entry.testimonial;
        } else {
            const translationFound = translations.find((tr) => tr.__component === this.state.language);

            if (translationFound) {
                testimonial = translationFound.Body;
            } else {
                testimonial = entry.testimonial;
            }
        }

        return testimonial;
    }

    render() {
        const { t } = this.props;

        return (
            <div className="sliderContainer">
                <div className="testimonialsTitle">
                    <h2 className="h2Text">{t('AboutTheProject.Testimonials')}</h2>
                </div>

                <Slider {...settings}>
                    {this.state.testimonials.map((testimonial, index) => (
                        <div className="testimonialsCarousel" key={index}>
                            <div className="testimonialsBody smallText testimonialsCentered">
                                {this.getTestimonialTranslation(testimonial)}
                            </div>
                        </div>
                    ))}
                </Slider>
            </div>
        );
    }
}

export default withTranslation(['translation'])(Testimonials);
