import React from 'react';
import { NavLink } from 'react-router-dom';

import NavBarComponent from '../../templates/NavBar/NavBar';
import MetaTags from '../../components/MetaTags/MetaTags';

import background from '../../assets/images/noAccount.svg';
import './NoAccount.css';

class NoAccount extends React.Component {
    render() {
        return (
            <div className="App" style={{ backgroundImage: `url(${background})` }}>
                <MetaTags title={`No Account | CinEd`} />
                <NavBarComponent />

                <div className="not-found-container">
                    <h2 className="not-found-title">It looks like you are not signed in!</h2>

                    <div className="not-found-buttons-wrapper">
                        <NavLink to="/login">
                            <button className="not-found-button">Sign In</button>
                        </NavLink>
                        <NavLink to="/registration">
                            <button className="not-found-button margin">Create New Account</button>
                        </NavLink>
                    </div>
                </div>
            </div>
        );
    }
}

export default NoAccount;
