import React from 'react';
import i18next from 'i18next';
import { Redirect } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import Body from '../../templates/Body/Body';
import BodyExercise from '../BodyExercise/BodyExercise';
import ExerciseIcons from '../BodyExercise/ExerciseIcons';
import Loading from '../../components/Loading/Loading';
import { BASE_URL } from '../../utils/globals';
import { getStudentSheetById } from '../../api/student-sheets';
import { AltDescriptionTranslations, DDescriptions, getExerciseDescription } from '../../utils/ssUtils';

import icon from '../../assets/images/student-sheets/readActivity.svg';
import './ExD.css';

class ExDClass extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            ex: null,
            loading: true,
            error: false,
            selectedButton: 0,
            viewMode: false,
        };
        this.exIndexButton = this.exIndexButton.bind(this);
    }

    async componentDidMount() {
        localStorage.setItem('lastPage', '/exD');
        const ssId = this.props.match.params.id;

        let response;
        try {
            response = await getStudentSheetById(ssId);
        } catch (error) {
            console.error('An error occurred: ', error);
            this.setState({ error: true });
            return;
        }

        this.setState({
            loading: false,
            ex: response,
        });

        const disableText = localStorage.getItem('disable');
        if (disableText) {
            this.setState({ viewMode: true });
        }

        const exerciseDetailsLength = response.D.Details.length;
        if (exerciseDetailsLength === 0) {
            return;
        }
    }

    exIndexButton(selectedButton) {
        this.setState({
            selectedButton: selectedButton,
        });
    }

    getExerciseImgDescription = (content, idx) => {
        const lngTranslation = DDescriptions[i18next.language];
        const enTranslation = DDescriptions['US'];

        const translation = content.D?.Details ? content.D?.Details[idx][lngTranslation] : undefined;
        const isValid = translation !== undefined;

        if (lngTranslation && isValid) {
            return translation;
        } else if (content.D) {
            return content.D?.Details[idx][enTranslation];
        } else return '';
    };

    render() {
        const checkToken = localStorage.getItem('token');
        if (!checkToken) {
            return <Redirect to="/no-account" />;
        }

        if (this.state.error) {
            return <Redirect to="/page-not-found" />;
        }

        if (this.state.loading) {
            return (
                <Body bodyClass="content" title="D Exercise | CinEd">
                    <Loading />
                </Body>
            );
        }

        const exercise = this.state.ex;

        const description = getExerciseDescription(this.state.ex, 'D', i18next.language, AltDescriptionTranslations);
        const imageCaption = exercise.D.Details[this.state.selectedButton].ImageCaption;
        const imgDescription = this.getExerciseImgDescription(exercise, this.state.selectedButton);

        const buttons = Array.from({ length: exercise.D.Details.length });

        return (
            <BodyExercise
                selectedOption="exD"
                studentSheetId={this.props.match.params.id}
                movieTitle={exercise?.MovieTitle}
                pageTitle={`D Exercise | ${exercise?.MovieTitle} | CinEd`}
                viewMode={this.state.viewMode}
                interactiveEx={false}
                prevLink={`/exC21/${this.props.match.params.id}`}
                nextLink={`/share/${this.props.match.params.id}`}
                lastEx={true}
            >
                <ExerciseIcons
                    studentSheetId={this.props.match.params.id}
                    exIcon={icon}
                    exTitle="StudentSheetsLeftMenu.GoFurther"
                    exDescription={description}
                    viewMode={this.state.viewMode}
                />

                <div className="exerciseContainer exD">
                    <div className="exerciseFlexWrapper exD">
                        <div className="textStructure">
                            <p className="captionExercise">{imageCaption}</p>
                            <p className="descriptionExercise">{imgDescription}</p>
                        </div>

                        <div className="imageExerciseDContainer">
                            <img
                                className="imageExerciseD"
                                src={`${BASE_URL}${exercise.D.Details[this.state.selectedButton].Image.url}`}
                                alt="imageFurther"
                            />
                        </div>
                    </div>
                </div>

                <div className="exerciseButtonsGroup">
                    <div className="buttonsGroupWrapper">
                        {buttons.map((value, idx) => (
                            <div key={idx}>
                                <button
                                    className={
                                        this.state.selectedButton === idx
                                            ? 'exerciseButtonSelected'
                                            : 'exerciseButtonNotSelected'
                                    }
                                    onClick={() => this.exIndexButton(idx)}
                                >
                                    {idx + 1}
                                </button>
                            </div>
                        ))}
                    </div>
                </div>
            </BodyExercise>
        );
    }
}

export default withTranslation(['translation'])(ExDClass);
