import React from 'react';
import { IconTypes } from '../../utils/utils';

import './anim.css';
import './warning.css';

export default function Anim(props) {
    if (props.iconType === IconTypes.SUCCESS) {
        return (
            <div className="animation-wrapper">
                <svg className="animation__svg checkmark__svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                    <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                    <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                </svg>
            </div>
        );
    } else if (props.iconType === IconTypes.ERROR) {
        return (
            <div className="animation-wrapper">
                <svg className="animation__svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                    <circle className="cross__circle" cx="26" cy="26" r="25" fill="none" />
                    <path className="cross__path cross__path--right" fill="none" d="M16,16 l20,20" />
                    <path className="cross__path cross__path--right" fill="none" d="M16,36 l20,-20" />
                </svg>
            </div>
        );
    } else {
        return (
            <div className="swal2-icon swal2-warning swal2-icon-show">
                <div className="swal2-icon-content">!</div>
            </div>
        );
    }
}
