import React from 'react';
import { withTranslation } from 'react-i18next';

import ABCinema from '../../assets/images/european-websites/abcinema.png';
import BFI from '../../assets/images/european-websites/bfi.png';
import ECFA from '../../assets/images/european-websites/ecfa.png';
import LeCinemas from '../../assets/images/european-websites/le-cinema.png';
import MovingCinema from '../../assets/images/european-websites/moving-cinema.png';
import Modal from '../../templates/Modal/Modal';

const Resources = [
    {
        id: 'abc',
        imgSrc: ABCinema,
        imgAlt: 'ABCinema Logo',
        title: 'ABCINEMA PROJECT',
        link: 'http://www.abcinemaproject.eu/',
        description: (
            <>
                ABCinema’s ambition is to encourage and help the cinephiles of tomorrow discover masterpieces of classic
                European cinema, thanks to a series of events and workshops created by the six partners of the project
                in <b>Belgium, France, Italy and the United Kingdom</b>.
            </>
        ),
    },
    {
        id: 'bfi',
        imgSrc: BFI,
        imgAlt: 'BFI Logo',
        title: 'EUROPEAN FRAMEWORK FOR FILM EDUCATION IN EUROPE',
        link: 'https://www2.bfi.org.uk/screening-literacy-film-education-europe',
        description: (
            <>
                Objective: create the foundations of a cinema education charter in order to develop cinema education in
                countries where it is underdeveloped. This project was started by the BFI (British Film Institute) and
                works with 18 countries. Its conclusions were presented on June 19, 2015 at La Cinémathèque française.{' '}
                <br />
                <br />
                In 2014-15, 25 partners (educational institutions and organizations) formed an advisory board on cinema
                education to assess the current state of cinema education in Europe and establish a common framework for{' '}
                <b>
                    Austria, the Czech Republic, France, Denmark, the United Kingdom (England and Scotland), Germany,
                    Sweden, Hungary, Greece, Ireland, Italy, Romania, Spain, Portugal, Poland and Cyprus
                </b>
                .
            </>
        ),
    },
    {
        id: 'ecfa',
        imgSrc: ECFA,
        imgAlt: 'ECFA Logo',
        title: "ASSOCIATION EUROPÉENNE DU CINÉMA POUR L'ENFANCE ET LA JEUNESSE'",
        link: 'https://www.ecfaweb.org/',
        description: (
            <>
                ECFA is an organization dedicated to anyone interested in high-quality films for children and young
                people: directors, producers, advertisers, distributors, exhibitors, TV programmemers, festival
                organizers and film educators. Resources are available in English and French.
            </>
        ),
    },
    {
        id: 'lecinemas',
        imgSrc: LeCinemas,
        imgAlt: 'Le Cinemas Logo',
        title: 'LE CINÉMA, CENT ANS DE JEUNESSE',
        link: 'https://www.cinematheque.fr/cinema100ansdejeunesse/',
        description: (
            <>
                International website that provides training materials, resources, tracks, exercises and filmmaking
                workshops, organized around issues in cinema: from the cinema education programme “Le Cinéma, cent ans
                de jeunesse”, implemented in Europe and around the world for more than 25 years. This programme which
                sets up an international network of coordinators is leaded by the association Cinéma, cent ans de
                jeunesse !, with some partners (Documentaire sur grand écran, Ciné 104 / Deutsches Filminstitut and
                Filmmuseum)
            </>
        ),
    },
    {
        id: 'movingcinema',
        imgSrc: MovingCinema,
        imgAlt: 'Moving Cinema Logo',
        title: 'MOVING CINEMA PROJECT',
        link: 'http://movingcinema.eu/',
        description: (
            <>
                This project focuses on applied research and provides methodologies, strategies and tools to children
                and young people so that they can appreciate European cinema and become active audience members.
                Different activity topics have been developed: film programmeming chosen by students, filmmaking
                workshops using cell phones and cameras, presence at festivals, dialogues with filmmakers and discovery
                of films through VoD platforms. The project was launched by <b>A Bao A Qu</b> and developed with{' '}
                <b>Meno Avilys</b> (Lithuania) and <b>Os Filhos de Lumière</b> (Portugal), collaborating with the Centre
                for the <b>Moving Image</b>
                (Scotland), <b>La Cinémathèque française</b> (France), the <b>British Film Institute</b> (United
                Kingdom) and <b>Kijufi</b> (Germany).
            </>
        ),
    },
];

class TeachersClass extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selected: null,
        };

        this.setSelectedModal = this.setSelectedModal.bind(this);
    }

    setSelectedModal(selected) {
        this.setState({
            selected: selected,
        });
    }

    render() {
        const { t } = this.props;
        const { selected } = this.state;

        return (
            <div className="gridLogos">
                {Resources.map((p) => (
                    <div className="pointer" key={p.id} onClick={() => this.setSelectedModal(p.id)}>
                        <img className="projectLogo" src={p.imgSrc} alt={p.imgAlt} />
                    </div>
                ))}

                {Resources.map((r) => (
                    <Modal
                        key={r.id}
                        id={r.id}
                        title={r.title}
                        titleClass="modalTitleHeader normalText"
                        closeTranslation={t('AboutTheProject.ModalCloseButton')}
                        imgSrc={r.imgSrc}
                        imgAlt={r.imgAlt}
                        link={r.link}
                        open={selected === r.id ? true : false}
                        closeModal={() => this.setSelectedModal(null)}
                    >
                        <div className="modalBody">
                            <p className="textModal smallText">{r.description}</p>
                        </div>
                    </Modal>
                ))}
            </div>
        );
    }
}

export default withTranslation(['translation'])(TeachersClass);
