import React from 'react';
import i18next from 'i18next';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { Redirect } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import Body from '../../templates/Body/Body';
import BodyExercise from '../BodyExercise/BodyExercise';
import ExerciseIcons from '../BodyExercise/ExerciseIcons';
import ImageEnlarger from '../../components/ImageEnlarger/ImageEnlarger';
import Loading from '../../components/Loading/Loading';
import Popup from '../../components/Popup/Popup';
import { BASE_URL } from '../../utils/globals';
import { updateStudentSheetAnswer } from '../../api/student-sheets';
import { DescriptionTranslations, getExerciseDescription, getInteractiveStudentSheet } from '../../utils/ssUtils';
import { toDataURL } from '../../utils/utils';

import download from '../../assets/images/student-sheets/download.svg';
import info from '../../assets/images/student-sheets/info.svg';
import reset from '../../assets/images/student-sheets/reset.svg';
import share from '../../assets/images/student-sheets/share.svg';
import save from '../../assets/images/student-sheets/save.svg';
import icon from '../../assets/images/student-sheets/dragDropActivity.svg';
import './ExA1.css';

const images = [1, 2, 3, 4, 5, 6, 7, 8];

class ExA1Class extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showModal: true,

            ex: null,
            loading: true,
            error: false,
            answerId: '',

            ExerciseA1_Text1: '',
            ExerciseA1_Text2: '',
            ExerciseA1_Text3: '',
            ExerciseA1_Text4: '',
            ExerciseA1_Text5: '',
            ExerciseA1_Text6: '',
            ExerciseA1_Text7: '',
            ExerciseA1_Text8: '',
            ExerciseA1_Image1: [],
            image1Id: '',
            ExerciseA1_Image2: [],
            image2Id: '',
            ExerciseA1_Image3: [],
            image3Id: '',
            ExerciseA1_Image4: [],
            image4Id: '',
            ExerciseA1_Image5: [],
            image5Id: '',
            ExerciseA1_Image6: [],
            image6Id: '',
            ExerciseA1_Image7: [],
            image7Id: '',
            ExerciseA1_Image8: [],
            image8Id: '',

            viewMode: false,
        };
        this.updateExercise = this.updateExercise.bind(this);
    }

    componentDidMount = async () => {
        localStorage.setItem('lastPage', '/exA1');
        const ssId = this.props.match.params.id;
        const user = JSON.parse(localStorage.getItem('user'));

        if (!localStorage.getItem('ssModal')) {
            localStorage.setItem('ssModal', 'alreadyWatched');
        } else {
            this.setState({ showModal: false });
        }

        let answer, exercise;
        try {
            const response = await getInteractiveStudentSheet(ssId, user.id);
            exercise = response.exercise;
            answer = response.answer;
        } catch (error) {
            console.error('An error occurred: ', error);
            this.setState({ error: true });
            return;
        }

        const disableText = localStorage.getItem('disable');
        this.updateExercise(answer, disableText);

        this.setState({
            ex: exercise,
            loading: false,
            answerId: answer?.id,
        });
    };

    updateExercise = (answer, disabled) => {
        for (let i = 1; i < 9; i++) {
            const ExerciseImageKey = `ExerciseA1_Image${i}`;
            const ImageIdKey = `image${i}Id`;
            const TextIdKey = `ExerciseA1_Text${i}`;

            const ExerciseImage = answer[ExerciseImageKey];
            const ExerciseText = answer[TextIdKey];

            if (ExerciseImage?.length > 0) {
                this.setState({
                    [ExerciseImageKey]: `${BASE_URL}${ExerciseImage[0].url}`,
                    [ImageIdKey]: ExerciseImage[0].id,
                    [TextIdKey]: ExerciseText,
                });
            } else {
                this.setState({
                    [TextIdKey]: ExerciseText,
                });
            }
        }

        if (disabled) {
            this.setState({ viewMode: true });
        } else {
            localStorage.setItem('code', answer?.Code);
        }
    };

    onDragStart = (ev) => {
        ev.dataTransfer.setData('text', ev.currentTarget.id);
    };

    handleElemDrop = (ev, boxNo) => {
        ev.preventDefault();

        const data = ev.dataTransfer.getData('text');
        const dataElem = document.getElementById(data);
        const img = dataElem?.getElementsByTagName('img')[0];

        if (!dataElem || !img) {
            return;
        }

        const imageId = `image${boxNo}Id`;
        const exerciseImage = `ExerciseA1_Image${boxNo}`;

        toDataURL(
            img.src,
            function (dataUrl) {
                this.setState({
                    [imageId]: data,
                    [exerciseImage]: dataUrl,
                });
            }.bind(this),
        );
    };

    onDrop = (ev, imgNo) => {
        if (this.state.viewMode) {
            return false;
        }
        this.handleElemDrop(ev, imgNo);
    };

    changeHandler = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    };

    submitHandler = async (e) => {
        e.preventDefault();

        if (this.state.loading) {
            return;
        }

        this.setState({ loading: true });

        const answerExercise = {
            ExerciseA1_Text1: this.state.ExerciseA1_Text1,
            ExerciseA1_Text2: this.state.ExerciseA1_Text2,
            ExerciseA1_Text3: this.state.ExerciseA1_Text3,
            ExerciseA1_Text4: this.state.ExerciseA1_Text4,
            ExerciseA1_Text5: this.state.ExerciseA1_Text5,
            ExerciseA1_Text6: this.state.ExerciseA1_Text6,
            ExerciseA1_Text7: this.state.ExerciseA1_Text7,
            ExerciseA1_Text8: this.state.ExerciseA1_Text8,
            ExerciseA1_Image1: this.state.image1Id,
            ExerciseA1_Image2: this.state.image2Id,
            ExerciseA1_Image3: this.state.image3Id,
            ExerciseA1_Image4: this.state.image4Id,
            ExerciseA1_Image5: this.state.image5Id,
            ExerciseA1_Image6: this.state.image6Id,
            ExerciseA1_Image7: this.state.image7Id,
            ExerciseA1_Image8: this.state.image8Id,
        };

        try {
            await updateStudentSheetAnswer(this.state.answerId, answerExercise);
        } catch (error) {
            console.log(error);
        }

        this.setState({ loading: false });
    };

    downloadExercise = (e) => {
        const exercise = document.querySelector('.dragDropWrapper');

        // clone exercise node and add styling compatible with PDF files
        const clone = exercise.cloneNode(true);
        clone.style.height = 'unset';
        clone.style.padding = '20px';
        clone.style.position = 'absolute';
        clone.style.bottom = 0;

        // get dynamic width of drag and drop box (image container)
        const imgContainer = document.querySelector('.dragDropBox');
        const style = window.getComputedStyle(imgContainer);
        const boxWidth = style.getPropertyValue('width');

        let width = boxWidth ? boxWidth : '150px';

        // transform textareas into divs
        Array.from(clone.querySelectorAll('textarea')).forEach((textArea) => {
            const div = document.createElement('div');

            div.innerText = textArea.value;
            div.style.wordBreak = 'break-word';
            div.style.width = width;
            div.style.fontSize = '10px';
            div.style.marginTop = '10px';
            div.style.marginBottom = '15px';
            textArea.style.display = 'none';
            textArea.parentElement.append(div);
        });

        // create container div and set opacity to 0
        // this hides the cloned div from the page, so that the user cannot see it
        const containerDiv = document.createElement('div');
        containerDiv.style.opacity = 0;
        containerDiv.appendChild(clone);

        // append created div to document so that it is possible to print it as PDF
        document.body.appendChild(containerDiv);

        html2canvas(clone, { useCORS: true, scale: 2 }).then((canvas) => {
            // remove cloned div as it is no longer needed
            document.body.removeChild(containerDiv);

            // convert canvas to image
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF('landscape');

            // center canvas in PDF file
            const pageWidth = pdf.internal.pageSize.getWidth();
            const pageHeight = pdf.internal.pageSize.getHeight();
            const widthRatio = pageWidth / canvas.width;
            const heightRatio = pageHeight / canvas.height;
            const ratio = widthRatio > heightRatio ? heightRatio : widthRatio;

            const canvasWidth = canvas.width * ratio;
            const canvasHeight = canvas.height * ratio;

            const marginX = (pageWidth - canvasWidth) / 2;
            const marginY = (pageHeight - canvasHeight) / 2;

            pdf.addImage(imgData, 'JPEG', marginX, marginY, canvasWidth, canvasHeight);
            const description = this.state.ex;

            if (description) {
                let movieTitle = description.MovieTitle;
                movieTitle = movieTitle.replace(/[^a-z0-9]/gi, '_');
                pdf.save(movieTitle + '_ExA1.pdf');
            } else {
                pdf.save('ExA1.pdf');
            }
        });
    };

    resetExercise = async (e) => {
        e.preventDefault();
        this.setState({ loading: true });

        const answerExercise = {
            ExerciseA1_Text1: '',
            ExerciseA1_Text2: '',
            ExerciseA1_Text3: '',
            ExerciseA1_Text4: '',
            ExerciseA1_Text5: '',
            ExerciseA1_Text6: '',
            ExerciseA1_Text7: '',
            ExerciseA1_Text8: '',
            ExerciseA1_Image1: [],
            ExerciseA1_Image2: [],
            ExerciseA1_Image3: [],
            ExerciseA1_Image4: [],
            ExerciseA1_Image5: [],
            ExerciseA1_Image6: [],
            ExerciseA1_Image7: [],
            ExerciseA1_Image8: [],
        };

        try {
            await updateStudentSheetAnswer(this.state.answerId, answerExercise);
        } catch (error) {
            console.log(error);
            return;
        }

        this.setState({
            ...answerExercise,
            image1Id: '',
            image2Id: '',
            image3Id: '',
            image4Id: '',
            image5Id: '',
            image6Id: '',
            image7Id: '',
            image8Id: '',
            loading: false,
        });
    };

    closeModal() {
        this.setState({ showModal: false });
    }

    render() {
        const { t } = this.props;

        const checkToken = localStorage.getItem('token');
        if (!checkToken) {
            return <Redirect to="/no-account" />;
        }

        if (this.state.error) {
            return <Redirect to="/page-not-found" />;
        }

        if (this.state.loading) {
            return (
                <Body bodyClass="content" title="A1 Exercise | CinEd">
                    <Loading />
                </Body>
            );
        }

        const exercise = this.state.ex;
        const imagesList = exercise?.A1?.Images;
        const description = getExerciseDescription(this.state.ex, 'A1', i18next.language, DescriptionTranslations);

        return (
            <BodyExercise
                selectedOption="exA1"
                studentSheetId={this.props.match.params.id}
                movieTitle={exercise?.MovieTitle}
                pageTitle={`A1 Exercise | ${exercise?.MovieTitle} | CinEd`}
                viewMode={this.state.viewMode}
                interactiveEx={true}
                nextLink={`/exA2/${this.props.match.params.id}`}
            >
                <ExerciseIcons
                    studentSheetId={this.props.match.params.id}
                    exIcon={icon}
                    exTitle="StudentSheetsLeftMenu.SeenInFilm"
                    exDescription={description}
                    viewMode={this.state.viewMode}
                    resetExercise={this.resetExercise}
                    submitHandler={this.submitHandler}
                    downloadExercise={this.downloadExercise}
                    activeReset
                    activeSubmit
                    activeDownload
                />

                <div className="exerciseFlexWrapper a1">
                    <div className="dragDropWrapper">
                        {images.map((imageNo) => (
                            <div key={imageNo} className="DragAndDropZone">
                                <div
                                    key={imageNo}
                                    className="dragDropBox"
                                    onDrop={(ev) => this.onDrop(ev, imageNo)}
                                    onDragOver={(ev) => ev.preventDefault()}
                                    name={`ExerciseA1_Image${imageNo}`}
                                    style={{ cursor: this.state.viewMode ? 'no-drop' : 'default' }}
                                >
                                    <div id={`ddi${imageNo}`}>
                                        {this.state[`ExerciseA1_Image${imageNo}`] ? (
                                            <ImageEnlarger
                                                className="imagesA1Drop"
                                                src={this.state[`ExerciseA1_Image${imageNo}`]}
                                                alt="Drop Box Image"
                                            />
                                        ) : (
                                            <></>
                                        )}
                                    </div>
                                </div>
                                <textarea
                                    className="answerBoxA1"
                                    id={`text${imageNo}`}
                                    placeholder={t('StudentSheetsContent.A1')}
                                    name={`ExerciseA1_Text${imageNo}`}
                                    value={this.state[`ExerciseA1_Text${imageNo}`]}
                                    disabled={this.state.viewMode}
                                    onDrop={(ev) => ev.preventDefault()}
                                    onChange={this.changeHandler}
                                />
                            </div>
                        ))}
                    </div>

                    <div className="line" />

                    <div className={`imagesA1Wrapper${this.state.viewMode ? ' disabled' : ''}`}>
                        {imagesList.map((image) => (
                            <div key={image?.id} className="imagesA1" id={image?.id} onDragStart={this.onDragStart}>
                                <ImageEnlarger
                                    src={`${BASE_URL}${image?.url}`}
                                    alt="Exercise A1 Images"
                                    style={{ cursor: this.state.viewMode ? 'default' : 'grab' }}
                                />
                            </div>
                        ))}
                    </div>
                </div>

                <Popup open={this.state.showModal} closeModal={() => this.closeModal()}>
                    <p className="textModalSS">{t('StudentSheetPopUp.Explanation')}</p>
                    <div className="modalHelpWrapper">
                        <div>
                            <img src={reset} alt="Reset exercise" height="40px" />
                            <p className="iconExplanation">{t('StudentSheetPopUp.Restart')}</p>
                        </div>
                        <div>
                            <img src={save} alt="Save exercise" height="40px" />
                            <p className="iconExplanation">{t('StudentSheetPopUp.Save')}</p>
                        </div>
                        <div>
                            <img src={share} alt="Share exercise" height="40px" />
                            <p className="iconExplanation">{t('StudentSheetPopUp.Share')}</p>
                        </div>
                        <div>
                            <img src={download} alt="Download exercise" height="40px" />
                            <p className="iconExplanation">{t('StudentSheetPopUp.Download')}</p>
                        </div>
                        <div>
                            <img src={info} alt="Exercise description" height="40px" />
                            <p className="iconExplanation">{t('StudentSheetPopUp.Info')}</p>
                        </div>
                    </div>
                </Popup>
            </BodyExercise>
        );
    }
}
export default withTranslation(['translation'])(ExA1Class);
