import React, { Suspense } from 'react';
import { ToastContainer } from 'react-toastify';
import ReactDOM from 'react-dom';
import { ThemeProvider } from '@mui/material';

import App from './App';
import Loading from './components/Loading/Loading';
import reportWebVitals from './reportWebVitals';
import theme from './MuiTheme';

import 'react-toastify/dist/ReactToastify.css';
import './i18n';
import './index.css';

// append app to dom
ReactDOM.render(
    <React.StrictMode>
        <ThemeProvider theme={theme}>
            <Suspense fallback={<Loading />}>
                <ToastContainer />
                <App />
            </Suspense>
        </ThemeProvider>
    </React.StrictMode>,
    document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
