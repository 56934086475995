import React from 'react';
import i18next from 'i18next';
import { withTranslation } from 'react-i18next';
import { Redirect } from 'react-router';

import Body from '../../templates/Body/Body';
import Loading from '../../components/Loading/Loading';
import MovieHeader from '../../components/MoviesPage/MovieHeader';
import MoviePhotoVideos from '../../components/MoviesPage/MoviePhotoVideos';
import Modal from '../../templates/Modal/Modal';
import TechnicalData from '../../components/TechnicalData/TechnicalData';
import { refreshSession } from '../../api/users';
import { getPedagogicalVideosById } from '../../api/pedagogical-videos';
import { isMovieOrdered } from '../../api/movie-orders';
import { removeSSFromStorage } from '../../utils/ssUtils';
import { getLanguageInitials, Translations } from '../../utils/utils';

const TechnicalDataKeys = ['Color', 'Type', 'ScreenPlay', 'Editing', 'Producer'];

class PedagogicalVideo extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            movie: null,
            loading: true,
            error: false,
            educationalFile: '',
            langInitials: 'en',
            isUserLoggedIn: false,
            isUserTeacher: false,
            isOrdered: false,
            selected: null,
        };

        this.checkIfVideoIsOrdered = this.checkIfVideoIsOrdered.bind(this);
        this.getVideoDetails = this.getVideoDetails.bind(this);
        this.updateEducationalFile = this.updateEducationalFile.bind(this);
        this.setSelectedModal = this.setSelectedModal.bind(this);
    }

    async componentDidMount() {
        const pvId = this.props.match.params.id;
        const token = localStorage.getItem('token');

        const languageInitials = getLanguageInitials(i18next.language);
        this.setState({ langInitials: languageInitials });

        let promises = [this.getVideoDetails(pvId)];

        if (token) {
            promises.push(this.checkIfVideoIsOrdered(pvId));
        }

        try {
            await Promise.all(promises);
        } catch (error) {
            console.error('An error occurred: ', error);
        }

        this.setState({
            loading: false,
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.t !== prevProps.t) {
            const movieDetails = this.state.movie;
            if (!movieDetails) {
                return;
            }

            const educationalFiles = movieDetails.educational_file;
            this.updateEducationalFile(i18next.language, educationalFiles);

            const languageInitials = getLanguageInitials(i18next.language);
            this.setState({ langInitials: languageInitials });
        }
    }

    async checkIfVideoIsOrdered(pvId) {
        let user;
        try {
            user = await refreshSession();
        } catch (error) {
            console.error('An error occurred: ', error);
            removeSSFromStorage();
            return;
        }

        let res;
        try {
            res = await isMovieOrdered(pvId, 'pedagogical');
        } catch (error) {
            console.error('An error occurred: ', error);
            return;
        }

        const isOrdered = res.result === true ? true : false;

        this.setState({
            isUserLoggedIn: true,
            isUserTeacher: user?.userRole !== 'Student' && user.customerId ? true : false,
            isOrdered: isOrdered,
        });
    }

    async getVideoDetails(pvId) {
        let video;

        try {
            video = await getPedagogicalVideosById(pvId);
        } catch (error) {
            console.error('An error occurred: ', error);
            this.setState({
                error: true,
            });
            return;
        }

        this.setState({
            movie: video,
        });

        const educationalFiles = video.educational_file;
        this.updateEducationalFile(i18next.language, educationalFiles);
    }

    updateEducationalFile(lang, educationalFiles) {
        let edFile;

        let language = Translations[lang] ? Translations[lang] : '';
        if (lang === 'DE') {
            language = 'German';
        } else if (language) {
            language = language.charAt(0).toUpperCase() + language.slice(1);
        }

        if (educationalFiles) {
            if (educationalFiles[language]) {
                edFile = educationalFiles[language].url;
            } else {
                edFile = educationalFiles?.English?.url;
            }
        }

        this.setState({
            educationalFile: edFile,
        });
    }

    updateIsOrdered(isOrdered) {
        this.setState({
            isOrdered: isOrdered,
        });
    }

    setSelectedModal(selected) {
        this.setState({
            selected: selected,
        });
    }

    render() {
        const { t } = this.props;
        const {
            error,
            movie,
            isOrdered,
            selected,
            educationalFile,
            langInitials,
            loading,
            isUserLoggedIn,
            isUserTeacher,
        } = this.state;

        if (loading) {
            return (
                <Body bodyClass="moviePage" title={`${t('NavBar.Movies')} | CinEd`}>
                    <Loading />
                </Body>
            );
        }

        if (error) {
            return <Redirect to="/page-not-found" />;
        }

        return (
            <Body bodyClass="moviePage" title={`${movie?.OriginalTitle} | CinEd`}>
                <MovieHeader
                    movie={movie}
                    educationalFile={educationalFile}
                    langInitials={langInitials}
                    isUserLoggedIn={isUserLoggedIn}
                    isUserTeacher={isUserTeacher}
                    isMovie={false}
                    isOrdered={isOrdered}
                    updateIsOrdered={this.updateIsOrdered.bind(this)}
                    setSelectedModal={(selected) => this.setSelectedModal(selected)}
                />

                <div className="container">
                    <MoviePhotoVideos
                        photoGallery={movie?.PhotoGallery?.Photo}
                        videoGallery={movie?.VideoGallery?.Video}
                    />
                </div>

                <Modal
                    id="td"
                    titleClass="modalTitleTD h3Text"
                    title={t('Movie.TechnicalData')}
                    closeTranslation={'Movie.Close'}
                    open={selected === 'td' ? true : false}
                    closeModal={() => this.setSelectedModal(null)}
                >
                    <TechnicalData movie={movie} technicaldata={TechnicalDataKeys} />
                </Modal>
            </Body>
        );
    }
}

export default withTranslation(['translation'])(PedagogicalVideo);
