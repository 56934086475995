import React from 'react';

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

import Gallery from '../../components/Gallery/Gallery';
import { BASE_URL } from '../../utils/globals';

import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import './ModalPhotoVideoGallery.css';

class ModalVideoGallery extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            items: this.props.gallery.map((video) => ({
                original: video,
                //thumbnail: video,
                renderItem: function renderItem() {
                    return (
                        <div className="image-gallery-image">
                            <video
                                src={`${BASE_URL}${video.url}`}
                                controls
                                controlsList="nodownload"
                                loop
                                //autoPlay
                                preload="metadata"
                                width="100%"
                            />
                        </div>
                    );
                },
            })),
        };
    }

    render() {
        console.log(this.props.gallery);
        return (
            <Modal
                open={this.props.openModal}
                onClose={this.props.hdlClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{
                    '*': {
                        boxSizing: 'content-box !important',
                    },
                    '::after, ::before': {
                        boxSizing: 'content-box !important',
                    },
                }}
            >
                <Box
                    className="box"
                    sx={{
                        bgcolor: 'background.paper',
                        p: 4,
                    }}
                >
                    <div style={{ textAlign: 'right' }}>
                        <IconButton sx={{ zIndex: '1', marginLeft: 'auto' }} onClick={this.props.hdlClose}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                    <Gallery items={this.state.items} imgIdx={this.props.imgIdx} />
                </Box>
            </Modal>
        );
    }
}

export default ModalVideoGallery;
