import React from 'react';
import { NavLink } from 'react-router-dom';

import NavBarComponent from '../../templates/NavBar/NavBar';
import MetaTags from '../../components/MetaTags/MetaTags';

import background from '../../assets/images/noAccount.svg';

class NoAccount extends React.Component {
    render() {
        return (
            <div className="App" style={{ backgroundImage: `url(${background})` }}>
                <MetaTags title={`Page Not Found | CinEd`} />
                <NavBarComponent />

                <div className="not-found-container">
                    <h2 className="not-found-title">404</h2>

                    <p className="not-found-description">The page you're looking for might have been removed.</p>

                    <div className="not-found-buttons-wrapper">
                        <NavLink to="/">
                            <button className="not-found-button">Return to Website</button>
                        </NavLink>
                    </div>
                </div>
            </div>
        );
    }
}

export default NoAccount;
