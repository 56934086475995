import React from 'react';
import Linkify from 'react-linkify';
import i18next from 'i18next';
import { withTranslation } from 'react-i18next';

import Body from '../../templates/Body/Body';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';

// Sign up page in different languages
import ENSignUpPage from '../../assets/images/user-guide/EN_Sign_Up_Page.png';

// Student registration in different languages
import ENStudentRegistration from '../../assets/images/user-guide/EN_Student_Registration.png';
import CZStudentRegistration from '../../assets/images/user-guide/CZ_Student_Registration.png';
import HRStudentRegistration from '../../assets/images/user-guide/HR_Student_Registration.png';
import PTStudentRegistration from '../../assets/images/user-guide/PT_Student_Registration.png';

// Teacher registration in different languages
import ENTeacherRegistration from '../../assets/images/user-guide/EN_Teacher_Registration.png';
import CZTeacherRegistration from '../../assets/images/user-guide/CZ_Teacher_Registration.png';
import HRTeacherRegistration from '../../assets/images/user-guide/HR_Teacher_Registration.png';
import PTTeacherRegistration from '../../assets/images/user-guide/PT_Teacher_Registration.png';

// Movie header (part 1) in different languages
import ENMovieHeader1 from '../../assets/images/user-guide/EN_Movie_Header_1.png';

// Sign in page in different languages
import ENSignInPage from '../../assets/images/user-guide/EN_Sign_In_Page.png';

// Video player download in different languages
import ENVideoPlayer from '../../assets/images/user-guide/EN_Video_Player_Download.png';

import VideoPlayer from '../../assets/images/user-guide/EN_Video_Player.png';
import PlayerMovie from '../../assets/images/user-guide/EN_Player_Movie_Page.png';

// Movie header in different languages
import ENMovieHeader2 from '../../assets/images/user-guide/EN_Movie_Header_2.png';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import './FAQ.css';

// User guides in different languages
const ENUserGuide = '/user-guides/EN_CinEd_User_Guide.pdf';
const CZUserGuide = '/user-guides/CZ_CinEd_User_Guide.pdf';
const HRUserGuide = '/user-guides/HR_CinEd_User_Guide.pdf';
const LTUserGuide = '/user-guides/LT_CinEd_User_Guide.pdf';
const GRUserGuide = '/user-guides/GR_CinEd_User_Guide.pdf';
const TRUserGuide = '/user-guides/TR_CinEd_User_Guide.pdf';
const SPUserGuide = '/user-guides/SP_CinEd_User_Guide.pdf';
const ITUserGuide = '/user-guides/IT_CinEd_User_Guide.pdf';
const DEUserGuide = '/user-guides/DE_CinEd_User_Guide.pdf';
const ROUserGuide = '/user-guides/RO_CinEd_User_Guide.pdf';
const FRUserGuide = '/user-guides/FR_CinEd_User_Guide.pdf';
const PTUserGuide = '/user-guides/PT_CinEd_User_Guide.pdf';
const BGUserGuide = '/user-guides/BG_CinEd_User_Guide.pdf';
const FIUserGuide = '/user-guides/FI_CinEd_User_Guide.pdf';

const UserGuideImages = {
    SignUpPage: {
        US: ENSignUpPage,
    },
    StudentRegistration: {
        US: ENStudentRegistration,
        CZ: CZStudentRegistration,
        HR: HRStudentRegistration,
        PT: PTStudentRegistration,
    },
    TeacherRegistration: {
        US: ENTeacherRegistration,
        CZ: CZTeacherRegistration,
        HR: HRTeacherRegistration,
        PT: PTTeacherRegistration,
    },
    MovieHeader1: {
        US: ENMovieHeader1,
    },
    SignInPage: {
        US: ENSignInPage,
    },
    VideoPlayerDownload: {
        US: ENVideoPlayer,
    },
    MovieHeader2: {
        US: ENMovieHeader2,
    },
};

const FAQ = [
    {
        question: 'FAQ.Q1',
        answer: 'FAQ.A1',
    },
    {
        question: 'FAQ.Q2',
        answer: 'FAQ.A2',
    },
    {
        question: 'FAQ.Q3',
        answer: 'FAQ.A3',
    },
    {
        question: 'FAQ.Q4',
        answer: 'FAQ.A4',
    },
    {
        question: 'FAQ.Q5',
        answer: 'FAQ.A5',
    },
];

class FAQClass extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            language: '',
        };

        this.getUserGuide.bind(this);
    }

    getUserGuide() {
        let userGuide = ENUserGuide;
        switch (i18next.language) {
            case 'US':
                userGuide = ENUserGuide;
                break;
            case 'HR':
                userGuide = HRUserGuide;
                break;
            case 'LT':
                userGuide = LTUserGuide;
                break;
            case 'GR':
                userGuide = GRUserGuide;
                break;
            case 'TR':
                userGuide = TRUserGuide;
                break;
            case 'SP':
                userGuide = SPUserGuide;
                break;
            case 'IT':
                userGuide = ITUserGuide;
                break;
            case 'DE':
                userGuide = DEUserGuide;
                break;
            case 'RO':
                userGuide = ROUserGuide;
                break;
            case 'FR':
                userGuide = FRUserGuide;
                break;
            case 'CZ':
                userGuide = CZUserGuide;
                break;
            case 'PT':
                userGuide = PTUserGuide;
                break;
            case 'BG':
                userGuide = BGUserGuide;
                break;
            case 'FI':
                userGuide = FIUserGuide;
                break;
            default:
                userGuide = ENUserGuide;
        }

        return userGuide;
    }

    getImageByKey(imgKey) {
        const img = UserGuideImages[imgKey][i18next.language];
        if (!img) return UserGuideImages[imgKey]['US'];
        else return img;
    }

    render() {
        const { t } = this.props;

        return (
            <Body bodyClass="content" title={`FAQ | CinEd`}>
                <div className="container pageContainer">
                    <h1 className="contactsTitle h1Text">
                        <b>{t('FAQ.Title')}</b>
                    </h1>
                    <h1 className="contactsSubTitle h3Text">{t('FAQ.SubTitle')}</h1>
                </div>

                <div className="accordionWidth">
                    {FAQ.map((item, idx) => (
                        <Accordion key={idx}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <p className="faqQuestion bigText">{t(item.question)}</p>
                            </AccordionSummary>
                            <AccordionDetails>
                                <p className="faqResponse normalText">{t(item.answer)}</p>
                            </AccordionDetails>
                        </Accordion>
                    ))}
                </div>

                <div className="container pageContainer">
                    <h1 className="contactsTitle h1Text">
                        <b>{t('UserGuide.Title')}</b>
                    </h1>
                    <h1 className="contactsSubTitle h3Text">{t('UserGuide.SubTitle')}</h1>
                </div>

                <div className="accordionWidth">
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <p className="faqQuestion bigText">
                                <b>1)</b> {t('UserGuide.Q1')}
                            </p>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Linkify>
                                <p className="userGuideDesc normalText">{t('UserGuide.A1Part1')}</p>

                                <img
                                    className="userGuideImg"
                                    src={this.getImageByKey('SignUpPage')}
                                    alt="Sign Up page"
                                />

                                <p className="userGuideDesc normalText">{t('UserGuide.A1Part2')}</p>

                                <img
                                    className="userGuideImg"
                                    src={this.getImageByKey('StudentRegistration')}
                                    alt="Student registration"
                                />

                                <p className="userGuideDesc normalText">{t('UserGuide.A1Part3')}</p>

                                <img
                                    className="userGuideImg"
                                    src={this.getImageByKey('TeacherRegistration')}
                                    alt="Teacher registration"
                                />

                                <p className="userGuideDesc normalText">{t('UserGuide.A1Part4')}</p>

                                <img
                                    className="userGuideImg"
                                    src={this.getImageByKey('MovieHeader1')}
                                    alt="Movie header after registration"
                                />
                            </Linkify>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <p className="faqQuestion bigText">
                                <b>2)</b> {t('UserGuide.Q2')}
                            </p>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Linkify>
                                <p className="userGuideDesc normalText">{t('UserGuide.A2Part1')}</p>

                                <img
                                    className="userGuideImg"
                                    src={this.getImageByKey('SignInPage')}
                                    alt="Sign In Page"
                                />

                                <p className="userGuideDesc normalText">{t('UserGuide.A2Part2')}</p>

                                <img
                                    className="userGuideImg"
                                    src={this.getImageByKey('VideoPlayerDownload')}
                                    alt="Video player download"
                                />

                                <p className="userGuideDesc normalText">{t('UserGuide.A2Part3')}</p>

                                <img className="userGuideImg" src={VideoPlayer} alt="Video Player" />

                                <p className="userGuideDesc normalText">{t('UserGuide.A2Part4')}</p>

                                <img className="userGuideImg" src={PlayerMovie} alt="Video Player Movie Page" />
                            </Linkify>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <p className="faqQuestion bigText">
                                <b>3)</b> {t('UserGuide.Q3')}
                            </p>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Linkify>
                                <p className="userGuideDesc normalText">{t('UserGuide.A3Part1')}</p>

                                <p className="userGuideDesc normalText">{t('UserGuide.A3Part2')}</p>

                                <img
                                    className="userGuideImg"
                                    src={this.getImageByKey('MovieHeader2')}
                                    alt="Movie header"
                                />
                            </Linkify>
                        </AccordionDetails>
                    </Accordion>

                    <p className="userGuideSection">
                        <span className="boldTxt h5Text">{t('UserGuide.DownloadUG')}</span>
                        <a
                            href={this.getUserGuide()}
                            className="cinedLargeBtn bigText"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <span>{t('UserGuide.UGFile')}</span>
                        </a>
                    </p>
                </div>
            </Body>
        );
    }
}

export default withTranslation(['translation'])(FAQClass);
