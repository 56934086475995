import { IP, PORT, PROTOCOL, PATH } from '../utils/globals';

export const Resource = {
    AUTH: 'auth',
    EVENTS_NEWS: 'events-and-news',
    EMAILS: 'emails',
    MOVIES: 'movies',
    MOVIE_ORDERS: 'movie-orders',
    PEDAGOGICAL_VIDEOS: 'pedagogical-videos',
    PHOTOGRAMS: 'photograms',
    SECTIONS: 'sections',
    STUDENT_SHEETS: 'student-sheet-englishes',
    SHEETS_ANSWERS: 'student-sheets-answers',
    TESTIMONIALS: 'testimonials',
    TRANSVERSAL_RESOURCES: 'transversal-resources',
    UPLOAD: 'upload',
    USERS: 'users',
    ORDERS: 'movie-orders',
};

export const HTTPMethod = {
    GET: 'get',
    POST: 'post',
    PUT: 'put',
    DELETE: 'delete',
    PATCH: 'patch',
};

class API {
    constructor(protocol, ip, port, path) {
        this.protocol = protocol ?? 'http';
        this.ip = ip ?? 'localhost';
        this.port = parseInt(port ?? '443');
        this.path = path ?? '';

        this.base = `${this.protocol}://${this.ip}:${this.port}${this.path}`;
    }
}

export const fetchRequest = (method, path, body = {}, authenticated = false, formData = false, cors = true) => {
    let headers = {};

    const userToken = localStorage.getItem('token');
    if (userToken && authenticated) {
        headers.Authorization = `Bearer ${userToken}`;
    }

    if (!formData) {
        headers['Accept'] = 'application/json';
        headers['Content-Type'] = 'application/json';
    }

    const options = { headers: headers };

    if (cors) options.mode = 'cors';

    if (method !== HTTPMethod.GET) {
        if (body) {
            options.body = formData ? body : JSON.stringify(body);
        }
        options.method = method;
    }

    console.log('[api] fetchRequest path', path);

    return new Promise((res, rej) => {
        fetch(path, options)
            .then((result) => {
                if (result.ok)
                    result
                        .json()
                        .then((final) => res(final))
                        .catch((error) => rej(error));
                else {
                    result
                        .json()
                        .then((error) => rej(error))
                        .catch(() => rej(result.statusText));
                }
            })
            .catch((error) => rej(error));
    });
};

export const apiInfo = new API(PROTOCOL, IP, PORT, PATH);
