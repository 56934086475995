import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import Orders from '../../pages/Orders/Orders';
import Profile from '../../pages/Profile/Profile';

class UserLayout extends Component {
    render() {
        const isLoggedIn = localStorage.getItem('token');
        if (!isLoggedIn) {
            return <Redirect to="/no-account" />;
        }

        return (
            <Switch>
                <Route path="/user/orders" component={Orders} />
                <Route path="/user/profile" component={Profile} />
                <Redirect to="/user/profile" />
            </Switch>
        );
    }
}

export default UserLayout;
