import React from 'react';
import { withTranslation } from 'react-i18next';

import Login from '../../components/Login/Login';
import MetaTags from '../../components/MetaTags/MetaTags';
import NavBarComponent from '../../templates/NavBar/NavBar';

import background from '../../assets/images/aniki-bobo.webp';
import './ModalAuth.css';
import './SignAuth.css';

class SignInPage extends React.Component {
    render() {
        const { t } = this.props;

        return (
            <div className="App" style={{ backgroundImage: `url(${background})` }}>
                <MetaTags title={`${t('NavBar.SignIn')} | CinEd`} />
                <NavBarComponent />

                <div className="signInForm authForm">
                    <Login forgotPassword={true} />
                </div>

                <div className="signInUpPlaceholder" />
            </div>
        );
    }
}

export default withTranslation(['translation'])(SignInPage);
