import React, { useState } from 'react';
import { browserName, browserVersion } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import MinimumRequirementsModal from './MinimumRequirements';
import Popup from './Popup';
import { getOS, lang } from '../../utils/utils';

import PlayDisable from '../../assets/images/collection/play_cined_off.svg';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

export default function NotSupported(props) {
    const [isMRmodalOpen, setIsMRmodalOpen] = useState(false);
    const { i18n, t } = useTranslation();
    return (
        <Popup maxWidth="md" {...props} isMRmodalOpen={isMRmodalOpen} setIsMRmodalOpen={setIsMRmodalOpen}>
            {isMRmodalOpen ? (
                <MinimumRequirementsModal />
            ) : (
                <div>
                    <div className="centeredContainer">
                        <img alt="Play Icon Dis" src={PlayDisable} className="playDisableIconNotSupportedModal" />
                    </div>

                    <div className="centeredContainer column">
                        <h2 className="modalTitleTD h4Text" lang={lang[i18n.language]}>
                            {t('Player.NotSupported')}
                        </h2>

                        <div className="normalText">
                            <div>
                                <ArrowForwardIcon className="listProjectIcon" />
                                <span className="boldTxt">{t('Player.OperatingSystem')}:</span> {getOS()}
                            </div>
                            <div>
                                <ArrowForwardIcon className="listProjectIcon" />
                                <span className="boldTxt">{t('Player.Browser')}:</span> {browserName} {browserVersion}
                            </div>
                        </div>
                        <div className="PopupContainer">
                            <a onClick={() => setIsMRmodalOpen(true)}>{t('Player.MinimumRequirementsButton')}</a>
                        </div>
                    </div>
                </div>
            )}
        </Popup>
    );
}
